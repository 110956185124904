import { Reviews } from 'src/pages/Reviews';
import { ReviewsPageRedesign } from 'src/pages/ReviewsPageRedesign';
import { useShowReviewsPageRedesign } from 'src/hooks/useShowReviewsPageRedesign';

export const ReviewsPage = () => {
  const { shouldShowReviewsPageRedesign } = useShowReviewsPageRedesign();

  // TODO: Add providers for the redesign here if needed.

  return (
    <>{shouldShowReviewsPageRedesign ? <ReviewsPageRedesign /> : <Reviews />}</>
  );
};
