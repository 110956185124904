import { pluralizeString } from '@/account/utils/pluralizeString';

import { formatDecimalToFraction } from './formatDecimalToFraction';

export function formatPortion(rawPortion: number) {
  const portion = formatDecimalToFraction(rawPortion);
  if (!portion) {
    return '';
  }

  return `${portion} ${pluralizeString(rawPortion, 'pack', 'packs')}`;
}
