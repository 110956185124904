import { CurrentFoodType } from '@farmersdog/lead-browser-storage/src/graphql/types';

import { BodyCondition } from '../../../validation/constants';

import type { Pet } from '../types';

type PetField = keyof Pet;

const PET_REQUIRED_FIELDS: PetField[] = [
  'name',
  'breeds',
  'weight',
  'birthday',
  'neutered',
  'activityLevel',
  'bodyCondition',
  'nature',
  'eatingStyle',
  'gender',
  'treatsQuantity',
  'foodType',
  'issues',
  'prescriptionDiets',
];

const TARGET_WEIGHT_REQUIRED_BODY_CONDITIONS = new Set([
  BodyCondition.TooSkinny,
  BodyCondition.Rounded,
  BodyCondition.Chunky,
]);

const FOOD_BRAND_REQUIRED_FOOD_TYPES = [
  CurrentFoodType.Dehydrated,
  CurrentFoodType.Dry,
  CurrentFoodType.Fresh,
  CurrentFoodType.Raw,
  CurrentFoodType.Wet,
];

export function doesBodyConditionRequireTargetWeight(
  bodyCondition: BodyCondition
) {
  return TARGET_WEIGHT_REQUIRED_BODY_CONDITIONS.has(bodyCondition);
}

// TODO: Use validations from Blueprint 2.0 here once that's in production
export function isPetInfoComplete(pet: Pet) {
  const requiredFields: PetField[] = [...PET_REQUIRED_FIELDS];

  if (
    pet.bodyCondition &&
    doesBodyConditionRequireTargetWeight(pet.bodyCondition as BodyCondition)
  ) {
    requiredFields.push('targetWeight');
  }

  if (
    pet.foodType &&
    FOOD_BRAND_REQUIRED_FOOD_TYPES.includes(pet.foodType as CurrentFoodType)
  ) {
    requiredFields.push('foodBrand');
  }

  return requiredFields.every(field => {
    const value = pet[field];

    if (field === 'breeds') {
      return value && (value as Pet['breeds']).length > 0;
    }

    return value !== null && value !== undefined && value !== '' && value !== 0;
  });
}
