import { Section, useViewport } from '@farmersdog/corgi-x';

import { useTrackedSectionRef } from '../../../../hooks/useTrackedSections';
import { HomepageRedesignSections } from '../CtaTrackerV2';

import styles from './BowlPackSection.module.css';
import { BowlPackSectionMobile } from './components/BowlPackSectionMobile';
import { BowlPackSectionDesktop } from './components/BowlPackSectionDesktop';

export const BowlPackSection = () => {
  const sectionRef = useTrackedSectionRef(HomepageRedesignSections.BowlPack);
  const viewport = useViewport();

  return (
    <Section
      aria-labelledby="bowl-pack-section-heading"
      className={styles.wrapper}
      sectionRef={sectionRef}
    >
      {!viewport.xs ? null : viewport.lg ? (
        <div className={styles.desktop}>
          <BowlPackSectionDesktop />
        </div>
      ) : (
        <div className={styles.mobile}>
          <BowlPackSectionMobile />
        </div>
      )}
    </Section>
  );
};
