import { motion } from 'motion/react';
import classNames from 'classnames';
import { Nowrap, Section, useViewport } from '@farmersdog/corgi-x';
import { HomepageEndToEndRedesignTreatments } from 'src/abTesting';
import { useShowHomepageRedesign } from 'src/hooks/useHomepageRedesign';
import { getDeliveryEstimateText } from 'src/pages/HomepageRedesign/utils';

import { ExtendedText } from '../../components';
import {
  SaucepanIconSvg,
  IngredientsIconSvg,
  DeliveredIconSvg,
} from './assets';
import { makeRoomTransition, makeRoomVariants } from './animations';

import styles from './HeroIconItemsDesktop.module.css';
import { useState } from 'react';

const heroIconItems = [
  {
    id: 'human-grade-safety',
    icon: IngredientsIconSvg,
    iconLabel: 'Dog food ingredients',
    text: (
      <>
        <Nowrap>Human-grade</Nowrap> <Nowrap>safety standards</Nowrap>
      </>
    ),
  },
  {
    id: 'delivered-to-your-door',
    icon: SaucepanIconSvg,
    iconLabel: 'Ingredients in Saucepan',
    text: (
      <>
        <Nowrap>Real food,</Nowrap> <Nowrap>gently cooked</Nowrap>
      </>
    ),
  },
  {
    id: 'loved-by-millions',
    icon: DeliveredIconSvg,
    iconLabel: 'Dog food delivery box',
    text: getDeliveryEstimateText('desktop') as JSX.Element,
  },
];

export const HeroIconItemsDesktop = () => {
  const { treatment } = useShowHomepageRedesign();
  const isAltOrderTreatment =
    treatment === HomepageEndToEndRedesignTreatments.Ols ||
    treatment === HomepageEndToEndRedesignTreatments.Millie;
  const shouldMakeRoom = !isAltOrderTreatment;

  const viewport = useViewport();

  const [animationComplete, setAnimationComplete] = useState(false);

  return (
    <Section as="div" className={styles.wrapper}>
      <div className={styles.container}>
        <div
          className={classNames(styles.itemsWrapper, {
            [styles.itemsWrapperWithRoom]: shouldMakeRoom,
          })}
        >
          <motion.div
            layout
            variants={shouldMakeRoom ? makeRoomVariants : undefined}
            initial="initial"
            animate={viewport.xl ? 'animateXL' : 'animateLG'}
            transition={animationComplete ? undefined : makeRoomTransition}
            onAnimationComplete={() => setAnimationComplete(true)}
            className={classNames(styles.itemsContainer, {
              [styles.itemsContainerWithRoom]: shouldMakeRoom,
            })}
          >
            {heroIconItems.map(item => (
              <div key={item.id} className={styles.item}>
                <item.icon
                  role="img"
                  aria-label={item.iconLabel}
                  className={styles.icon}
                />
                <ExtendedText
                  variant="heading-18"
                  weight="semibold"
                  color="Kale3"
                >
                  {item.text}
                </ExtendedText>
              </div>
            ))}
          </motion.div>
        </div>
      </div>
    </Section>
  );
};
