import type { ImageSources } from '@farmersdog/corgi-x';
import {
  Breakpoint,
  DESKTOP_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
} from '@farmersdog/corgi-x';

export function generateSrcSetString(src: string, size: number): string {
  return `${src} ${size}w`;
}

type SourcesForPreloaderProps = {
  mobileSources?: ImageSources;
  desktopSources: ImageSources;
};

export function generateSourcesForPreloader({
  mobileSources,
  desktopSources,
}: SourcesForPreloaderProps) {
  const sources = [];

  if (mobileSources) {
    const mobileSrcSetStrings = [
      mobileSources.webP,
      mobileSources.jpg,
      mobileSources.png,
    ]
      .map(src => (src ? generateSrcSetString(src, Breakpoint.MD) : undefined))
      .filter(src => src !== undefined);

    const mobileSourcesForPreloader = {
      imageSrcSet: mobileSrcSetStrings.join(', '),
      media: MOBILE_MEDIA_QUERY,
    };

    sources.push(mobileSourcesForPreloader);
  }

  const desktopSrcSetStrings = [
    desktopSources.webP,
    desktopSources.jpg,
    desktopSources.png,
  ]
    .map(src => (src ? generateSrcSetString(src, Breakpoint.LG) : undefined))
    .filter(src => src !== undefined);

  const desktopSourcesForPreloader = {
    imageSrcSet: desktopSrcSetStrings.join(', '),
    media: DESKTOP_MEDIA_QUERY,
  };

  sources.push(desktopSourcesForPreloader);

  return sources;
}
