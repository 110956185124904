import { Grid, Picture, Text } from '@farmersdog/corgi-x';
import { getLinkToFreshSignup } from '@farmersdog/tosa/src/utils/getLinkToSignupFlow';
import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import classNames from 'classnames';
import { motion } from 'motion/react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { useState } from 'react';
import { useShowHomepageRedesign } from 'src/hooks/useHomepageRedesign';
import { ExtendedButton } from '../../../ExtendedButton';
import { CtaTrackerV2, HomepageRedesignSections } from '../../../CtaTrackerV2';

import {
  BowlArrowLottieJson,
  bowlDesktopSources,
  bowlShadowDesktopSources,
  PackArrowLottieJson,
  packDesktopSources,
} from './assets';
import {
  fadeIn,
  rollInFromLeft,
  shadowFadeIn,
  slideInFromRight,
  staggeredFadeIn,
} from './animations';
import styles from './BowlPackSectionDesktop.module.css';

export const BowlPackSectionDesktop = () => {
  const [triggerBowlArrow, setTriggerBowlArrow] = useState(false);
  const [triggerPackArrow, setTriggerPackArrow] = useState(false);
  const [bowlSectionIsInView, setBowlSectionIsInView] = useState(false);
  const [packSectionIsInView, setPackSectionIsInView] = useState(false);

  const linkToFreshSignup = getLinkToFreshSignup(PATH_SIGNUP_ME);
  const { treatment } = useShowHomepageRedesign();

  return (
    <Grid
      flexDirection="column"
      gap="lg"
      className={classNames(styles.wrapper, styles[treatment])}
    >
      <motion.div
        onViewportEnter={() => setBowlSectionIsInView(true)}
        viewport={{ once: true }}
        className={styles.bowlSection}
      >
        <motion.div
          layout
          className={classNames(styles.bowlContainer, styles[treatment])}
          variants={fadeIn}
          initial="hidden"
          animate={bowlSectionIsInView && 'visible'}
        >
          <motion.div
            layout
            className={styles.bowlImageWrapper}
            variants={rollInFromLeft}
            initial="hidden"
            animate={bowlSectionIsInView && 'visible'}
          >
            <Picture
              className={styles.bowlImage}
              sources={[bowlDesktopSources.webP, bowlDesktopSources.png]}
              alt="Turkey Bowl"
            />
          </motion.div>
          <motion.div
            variants={shadowFadeIn}
            initial="hidden"
            animate={bowlSectionIsInView && 'visible'}
          >
            <Picture
              sources={[
                bowlShadowDesktopSources.webP,
                bowlShadowDesktopSources.png,
              ]}
              alt=""
              className={styles.bowlShadowImage}
            />
          </motion.div>
        </motion.div>
        <motion.div
          key="bowl-content"
          className={styles.bowlContent}
          viewport={{ once: true, amount: 0.4 }}
          initial="hidden"
          whileInView="visible"
          variants={staggeredFadeIn.container}
        >
          <motion.div variants={staggeredFadeIn.item}>
            <Text
              variant="heading-40"
              as="h2"
              bold
              color="Kale3"
              id="bowl-pack-section-heading"
            >
              All the nutrients without the extreme processing
            </Text>
          </motion.div>

          <motion.div
            variants={staggeredFadeIn.item}
            onAnimationComplete={() => setTriggerBowlArrow(true)}
          >
            <Text weight="semibold" color="Kale3" variant="heading-22" as="p">
              Unlike most pet foods, our food is gently cooked using whole
              ingredients and balanced with the vitamins and minerals your dog
              needs.
            </Text>
          </motion.div>
          <div className={styles.bowlArrowContainer}>
            {triggerBowlArrow && (
              <DotLottieReact
                aria-hidden="true"
                autoplay
                loop={false}
                data={BowlArrowLottieJson}
                className={styles.bowlArrow}
              />
            )}
          </div>
        </motion.div>
      </motion.div>
      <div className={styles.packSection}>
        <motion.div
          key="pack-content"
          initial="hidden"
          whileInView="visible"
          variants={staggeredFadeIn.container}
          viewport={{ once: true, amount: 0.25 }}
          onViewportEnter={() => setPackSectionIsInView(true)}
          className={styles.packContent}
        >
          <motion.div variants={staggeredFadeIn.item}>
            <Text color="Kale3" variant="heading-40" bold as="h2">
              The right amount of food every time
            </Text>
          </motion.div>
          <motion.div
            variants={staggeredFadeIn.item}
            onAnimationComplete={() => setTriggerPackArrow(true)}
          >
            <Text
              weight="semibold"
              color="Kale3"
              variant="heading-22"
              as="p"
              topSpacing="md"
              bottomSpacing="lg"
            >
              Pre-portioned packs make it easy to
              <br />
              keep your dog at a healthy weight.
              <br />
              No more guessing or vague scoops.
            </Text>
          </motion.div>
          {triggerPackArrow && (
            <DotLottieReact
              aria-hidden="true"
              autoplay
              loop={false}
              data={PackArrowLottieJson}
              className={styles.packArrow}
            />
          )}
          <motion.div variants={staggeredFadeIn.item}>
            <CtaTrackerV2
              type="select"
              moduleLevel={1}
              moduleName={HomepageRedesignSections.BowlPack}
            >
              <ExtendedButton
                variant="solid-kale"
                type="link"
                to={linkToFreshSignup}
              >
                Build Your Plan
              </ExtendedButton>
            </CtaTrackerV2>
          </motion.div>
        </motion.div>
        <motion.div
          className={styles.packImageWrapper}
          variants={slideInFromRight}
          initial="hidden"
          animate={packSectionIsInView && 'visible'}
        >
          <Picture
            sources={[packDesktopSources.webP, packDesktopSources.png]}
            alt="Turkey Pack"
            className={styles.packImage}
          />
        </motion.div>
      </div>
    </Grid>
  );
};
