export interface ApplovinItem {
  item_variant_id: string;
  item_id: string;
  item_name: string;
  image_url: string;
  item_category_id: number;
  price: number;
  quantity: number;
}

export const applovinItem: ApplovinItem = {
  item_variant_id: '1',
  item_id: 'FRESH_FOOD',
  item_name: 'Fresh Dog Food',
  image_url: 'https://www.thefarmersdog.com/static/logo_1.png',
  item_category_id: 543682,
  price: 1,
  quantity: 1,
};
