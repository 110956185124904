import { useCallback, useMemo, useRef } from 'react';

import { NodeNames } from '@farmersdog/constants';

import { useSignupUpdatedAssets } from '../../..//hooks';
import { trackTriedToChangeInputSelection } from '../../../analytics';
import { getNodeNameAndPosition } from '../../../blueprint/utils';
import { useSignupCardSelectors } from '../../../hooks/experiments/useSignupCardsSelectors';
import { useLabel } from '../../../hooks/useLabel';
import { useLingerAndSubmitFormById } from '../../../hooks/useLingerAndSubmitFormById';
import { SubmitButton } from '../../shared';
import { RadioSelectorGroup } from '../base';
import { CardRadioGroup } from '../base/CardRadioGroup';
import { getIconAltTag } from '../base/RadioSelectorGroup/utils';

import {
  eatingStyleAssets,
  EatingStyleAssets,
  eatingStyleNewAssets,
  cardEatingStyleAssets,
} from './assets';
import styles from './EatingStyleInput.module.css';

import type { TOSAComponentInput, TOSALeafNode } from '../../../types';
import type { Value } from '../base/CardRadioGroup';

export function EatingStyleInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, formMethods, useFeature, formSubmitRequest, parentFormId } =
    props;
  const { getValues, register } = formMethods;
  const { position } = getNodeNameAndPosition(node.name);

  const registerProps = register(node.name);
  const eatingStyleField = `${NodeNames.EatingStyle}-${position}` as const;
  const currentValue = getValues(eatingStyleField) ?? null;
  const hasInitialValue = useRef(!!currentValue);
  const { frontLabel } = useLabel({ node, getValues });

  const options =
    node.input?.options?.map(({ name, value, description }) => ({
      label: name,
      value,
      description,
    })) ?? [];

  const { isSignupUpdatedAssetsOn } = useSignupUpdatedAssets({
    useFeature,
  });

  const imageAssets = isSignupUpdatedAssetsOn
    ? eatingStyleNewAssets
    : eatingStyleAssets;

  const cardGroupOptions = useMemo(() => {
    return (
      node.input?.options?.map(({ name, value, description }, index) => ({
        heading: name,
        value,
        description,
        imageSources: cardEatingStyleAssets.large[index],
        imageAlt: getIconAltTag(value),
      })) ?? []
    );
  }, [node.input?.options]);

  const { shouldShowSignupCardSelectors, shouldIncludeContinueBtn } =
    useSignupCardSelectors({
      useFeature,
    });

  const { queueSubmitForm, isFormSubmissionQueued } =
    useLingerAndSubmitFormById(parentFormId);

  const cardGroupOnChange = useCallback(
    async (value: Value) => {
      const event = {
        target: {
          name: node.name,
          value,
        },
      };

      await registerProps.onChange(event);

      if (!shouldIncludeContinueBtn && !hasInitialValue.current) {
        queueSubmitForm();

        if (isFormSubmissionQueued) {
          trackTriedToChangeInputSelection();
        }
      }
    },
    [
      node.name,
      registerProps,
      shouldIncludeContinueBtn,
      queueSubmitForm,
      isFormSubmissionQueued,
    ]
  );

  return (
    <>
      <span>{frontLabel}</span>

      {shouldShowSignupCardSelectors ? (
        <div className={styles.wrapper}>
          <CardRadioGroup
            imageClassname={styles.image}
            options={cardGroupOptions}
            name={registerProps.name}
            currentValue={currentValue}
            onChange={cardGroupOnChange}
            groupDescription="Select the eating style closest to your dog's."
          />
        </div>
      ) : (
        <RadioSelectorGroup
          registerProps={registerProps}
          options={options}
          icons={imageAssets}
          defaultMobileIconIndex={EatingStyleAssets.GoodEater}
          legend="Eating Style"
          currentValue={currentValue}
          defaultDescription={
            currentValue ? '' : "Select the eating style closest to your dog's."
          }
        />
      )}

      {currentValue && !isFormSubmissionQueued && (
        <SubmitButton loading={formSubmitRequest.loading} {...props} />
      )}
    </>
  );
}
