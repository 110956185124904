import { track } from '@farmersdog/analytics';

import { segmentTrackEvents } from '../segmentTrackEventNames';

interface TrackChangedInputSelectionProps {
  previousSelection: string;
  newSelection: string;
}

export function trackChangedInputSelection({
  previousSelection,
  newSelection,
}: TrackChangedInputSelectionProps) {
  track(segmentTrackEvents.signup_changed_input_selection, {
    previousSelection,
    newSelection,
  });
}
