import { useMemo } from 'react';
import { useShowHomepageRedesign } from 'src/hooks/useHomepageRedesign';
import { HomepageEndToEndRedesignTreatments } from 'src/abTesting';
import {
  type BenefitCardVariant,
  BetterForThem,
  BowlPackSection,
  ContactUs,
  DidYouKnowSection,
  FAQ,
  LifetimeOfBenefits,
  PressBarSection,
  StoriesFromSection,
  Survey,
  VetsKnowBest,
} from '../../components';

interface HomepageSectionsProps {
  onDrawerOpen: (key: BenefitCardVariant) => void;
}

export const HomepageSections = ({ onDrawerOpen }: HomepageSectionsProps) => {
  const { treatment } = useShowHomepageRedesign();

  const sectionComponents = useMemo(
    () => ({
      bowlPack: <BowlPackSection />,
      didYouKnow: <DidYouKnowSection />,
      lifetime: <LifetimeOfBenefits onTileClick={onDrawerOpen} />,
      pressBar: <PressBarSection />,
      betterForThem: <BetterForThem />,
      stories: <StoriesFromSection />,
      survey: <Survey />,
      vets: <VetsKnowBest />,
      faq: <FAQ />,
      contact: <ContactUs />,
    }),
    [onDrawerOpen]
  );

  const sortedSections = useMemo(() => {
    const defaultOrder = [
      sectionComponents.bowlPack,
      sectionComponents.didYouKnow,
      sectionComponents.lifetime,
      sectionComponents.pressBar,
      sectionComponents.betterForThem,
      sectionComponents.stories,
      sectionComponents.survey,
      sectionComponents.vets,
      sectionComponents.faq,
      sectionComponents.contact,
    ];

    const altOrder = [
      sectionComponents.lifetime,
      sectionComponents.survey,
      sectionComponents.bowlPack,
      sectionComponents.didYouKnow,
      sectionComponents.pressBar,
      sectionComponents.betterForThem,
      sectionComponents.stories,
      sectionComponents.vets,
      sectionComponents.faq,
      sectionComponents.contact,
    ];

    const isAltOrderTreatment =
      treatment === HomepageEndToEndRedesignTreatments.Ols ||
      treatment === HomepageEndToEndRedesignTreatments.Millie;

    return isAltOrderTreatment ? altOrder : defaultOrder;
  }, [treatment, sectionComponents]);

  return <>{sortedSections}</>;
};
