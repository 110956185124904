import type { NextLink, Operation } from '@apollo/client';
import type { NetworkError } from '@apollo/client/errors';

import { ApplicationError, type ValidationError } from 'src/errors';
import * as errorCodes from 'src/errors/errorCodes';
import { reporter } from 'src/services/reporter';

interface HandleNetworkErrorArgs {
  networkError: Exclude<NetworkError, null>;
  forward: NextLink;
  operation: Operation;
}

export function handleNetworkError({
  networkError,
  forward,
  operation,
}: HandleNetworkErrorArgs) {
  const errorContext = {
    operationName: operation.operationName,
    networkError,
  };

  reporter.error(
    new ApplicationError(
      'GraphQL request resulted in network error',
      errorContext
    ),
    errorContext
  );

  const networkErrorResult =
    'result' in networkError ? networkError.result : null;

  const resultHasErrors =
    !!networkErrorResult &&
    typeof networkErrorResult !== 'string' &&
    'data' in networkErrorResult;

  const isStripeCardDeclinedError =
    resultHasErrors &&
    /* eslint-disable-next-line */
    !!networkErrorResult.data?.errors?.some(
      (err: ValidationError) => err?.code === errorCodes.CHARGE_DECLINED
    );

  if (isStripeCardDeclinedError) {
    return;
  }

  return forward(operation);
}
