import { CvrSignupCardSelectorsTreatments, FeatureName } from '../../../utils';
import { useTosaUserFeature } from '../../useTosaUserFeature';

import type { UseFeatureHook } from '../../../types';

interface useSignupCardSelectorProps {
  useFeature: UseFeatureHook;
}

export const LINGER_TIME_BEFORE_SUBMIT = 500;

const OFF_TREATMENTS = [
  CvrSignupCardSelectorsTreatments.off,
  CvrSignupCardSelectorsTreatments.control,
];

const SHOW_CONTINUE_BUTTON_TREATMENTS = [
  ...OFF_TREATMENTS,
  CvrSignupCardSelectorsTreatments.with_continue_button,
];

export function useSignupCardSelectors({
  useFeature,
}: useSignupCardSelectorProps) {
  const { treatment } = useTosaUserFeature({
    useFeature,
    featureName: FeatureName.CVR_SIGNUP_CARD_SELECTORS,
  });

  const shouldShowSignupCardSelectors = !OFF_TREATMENTS.includes(
    treatment as CvrSignupCardSelectorsTreatments
  );
  const shouldIncludeContinueBtn = SHOW_CONTINUE_BUTTON_TREATMENTS.includes(
    treatment as CvrSignupCardSelectorsTreatments
  );

  return {
    shouldIncludeContinueBtn,
    shouldShowSignupCardSelectors,
  };
}
