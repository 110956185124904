import HeroMillieVariantDesktopJpg from './hero-millie-desktop.jpg';
import HeroMillieVariantDesktopWebp from './hero-millie-desktop.webp';
import HeroMillieVariantMobileJpg from './hero-millie-mobile.jpg';
import HeroMillieVariantMobileWebp from './hero-millie-mobile.webp';

export const heroMillieVariantSources = {
  mobileSources: {
    webP: HeroMillieVariantMobileWebp,
    jpg: HeroMillieVariantMobileJpg,
  },
  desktopSources: {
    webP: HeroMillieVariantDesktopWebp,
    jpg: HeroMillieVariantDesktopJpg,
  },
};
