import copy from 'copy-to-clipboard';
import { useCallback, useEffect, useState } from 'react';

import { Button } from '@farmersdog/corgi-x';

import styles from './TosaDevTools.module.css';

interface CopyButtonProps {
  valueToCopy: string | undefined;
  label: string;
}

const BUTTON_DISABLE_TIMEOUT = 2_000;

export function CopyButton({ valueToCopy, label }: CopyButtonProps) {
  const [isValueRecentlyCopied, setIsValueRecentlyCopied] =
    useState<boolean>(false);

  const handleCopy = useCallback(() => {
    if (valueToCopy) {
      setIsValueRecentlyCopied(true);
      copy(valueToCopy);
    }
  }, [valueToCopy, setIsValueRecentlyCopied]);

  useEffect(() => {
    if (isValueRecentlyCopied) {
      const timeoutId = setTimeout(() => {
        setIsValueRecentlyCopied(false);
      }, BUTTON_DISABLE_TIMEOUT);
      return () => clearTimeout(timeoutId);
    }
    return;
  }, [isValueRecentlyCopied, setIsValueRecentlyCopied]);

  return (
    <Button
      type="button"
      onClick={handleCopy}
      disabled={!valueToCopy || isValueRecentlyCopied}
      className={styles.copyButton}
    >
      {isValueRecentlyCopied ? `Copied ${label}!` : `Copy ${label}`}
    </Button>
  );
}
