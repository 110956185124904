import { HomepageEndToEndRedesignTreatments } from 'src/abTesting';
import { generateSourcesForPreloader } from 'src/pages/Home/components/AssetPreloader/assets/shared';
import { benefitCardSourcesForPreloader } from 'src/pages/HomepageRedesign/components/LifetimeOfBenefits/assets/cards';

export function getPreloadLinksForBenefitCards(
  treatment: HomepageEndToEndRedesignTreatments
) {
  // Only preload for OLS and Millie treatments
  if (
    treatment !== HomepageEndToEndRedesignTreatments.Ols &&
    treatment !== HomepageEndToEndRedesignTreatments.Millie
  ) {
    return [];
  }

  return benefitCardSourcesForPreloader.flatMap(sources => {
    const sourceData = {
      webP: sources.webP,
      jpg: sources.jpg,
    };

    // We only want to preload desktop sources.
    return generateSourcesForPreloader({
      desktopSources: sourceData,
    });
  });
}
