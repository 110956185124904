import type { MobileAndDesktopSources } from '@farmersdog/corgi-x';
import { Logger } from '@farmersdog/logger';

import { HomepageHeroImageTreatments } from 'src/abTesting';
import {
  desktopLovedByHumans,
  mobileLovedByHumans,
} from 'src/pages/Home/components/AssetPreloader/assets/loved-by-humans';
import type { CurrentHomepageHeroTreatment } from 'src/hooks/useHomepageHeroExperiment';

const OFF_TREATMENTS = [
  HomepageHeroImageTreatments.Off,
  HomepageHeroImageTreatments.Control,
];

export const homepageHeroTreatmentToAssetsMap: Record<
  CurrentHomepageHeroTreatment,
  MobileAndDesktopSources
> = {
  [HomepageHeroImageTreatments.LovedByHumans]: {
    mobileSources: mobileLovedByHumans,
    desktopSources: desktopLovedByHumans,
  },
  [HomepageHeroImageTreatments.CtaOnly50Off]: {
    mobileSources: mobileLovedByHumans,
    desktopSources: desktopLovedByHumans,
  },
  [HomepageHeroImageTreatments.CtaOnlySeePlansAndPricing]: {
    mobileSources: mobileLovedByHumans,
    desktopSources: desktopLovedByHumans,
  },
};

const DEFAULT_ASSETS =
  homepageHeroTreatmentToAssetsMap[HomepageHeroImageTreatments.LovedByHumans];

export function getHomepageHeroAssets(
  treatment: string
): MobileAndDesktopSources {
  const assets =
    homepageHeroTreatmentToAssetsMap[treatment as CurrentHomepageHeroTreatment];

  if (
    !assets &&
    !OFF_TREATMENTS.includes(treatment as HomepageHeroImageTreatments)
  ) {
    const log = new Logger('website:homepageHeroAssets');
    log.error('Homepage hero treatment not recognized', {
      treatment,
    });
  }

  return assets ?? DEFAULT_ASSETS;
}
