import type { NextFunction, Request, Response } from 'express';

import { Logger } from '@farmersdog/logger';

import { getTreatmentsWithParsedConfigs } from './getTreatmentsWithParsedConfigs';

const log = new Logger('app:server:experimentsMiddleware');

export async function experimentsMiddleware(
  request: Request,
  _: Response,
  next: NextFunction
) {
  try {
    const splitAnonymousTreatments = await getTreatmentsWithParsedConfigs({
      request,
    });

    request.experiments = splitAnonymousTreatments;
  } catch (error) {
    log.error('SSR Split - Failed to fetch split treatments', {
      error,
      anonymousId: request.anonymousId,
    });
  } finally {
    next();
  }
}
