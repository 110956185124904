import type { MobileAndDesktopSources } from '@farmersdog/corgi-x';

import type {
  HomepageEndToEndRedesignTreatments,
  HomepageHeroImageTreatments,
} from 'src/abTesting';

import {
  getHomepageHeroAssets,
  getHomepageRedesignAssets,
  isActiveHomepageRedesignTreatment,
} from './utils';

interface GetSourcesForHomepageArgs {
  homepageRedesignTreatment: HomepageEndToEndRedesignTreatments;
  homepageHeroTreatment: HomepageHeroImageTreatments;
}
export function getSourcesForHomepageHero({
  homepageRedesignTreatment,
  homepageHeroTreatment,
}: GetSourcesForHomepageArgs): MobileAndDesktopSources {
  if (isActiveHomepageRedesignTreatment(homepageRedesignTreatment)) {
    return getHomepageRedesignAssets(homepageRedesignTreatment);
  }

  return getHomepageHeroAssets(homepageHeroTreatment);
}
