import { Script } from 'src/components/Script';
import config from 'src/config';

export function DatadogScript() {
  if (!config('datadogRum.enabled')) {
    return null;
  }

  // https://docs.datadoghq.com/real_user_monitoring/browser/#cdn-async
  return (
    <Script>
      {`(function(h,o,u,n,d) {
        h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
        d=o.createElement(u);d.async=1;d.src=n
        n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
      })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v6/datadog-rum.js','DD_RUM')
        DD_RUM && DD_RUM.onReady(function() {
          DD_RUM.init({
            applicationId: '${config('datadogRum.applicationId')}',
            clientToken: '${config('datadogRum.clientToken')}',
            site: 'datadoghq.com',
            service: 'website',
            env: '${config('app.env')}',
            version: '${config('app.version')}',
            sessionSampleRate: ${config('datadogRum.sampleRate')},
            sessionReplaySampleRate: ${config(
              'datadogRum.sessionReplaySampleRate'
            )},
            trackUserInteractions: false,
            trackResources: false,
            trackLongTasks: false,
            allowedTracingUrls: ['${config('app.apiUrl')}']
          })
        })`}
    </Script>
  );
}
