import classNames from 'classnames';

import {
  generateSourcesForPicture,
  Picture,
  type MobileAndDesktopSources,
} from '@farmersdog/corgi-x';

import styles from './FullScreenImageBackground.module.css';

interface PictureComponentProps {
  moveBackgroundLower?: boolean;
  sources: MobileAndDesktopSources;
  alt: string;
  className?: string;
}

export const FullScreenImageBackground = ({
  sources,
  moveBackgroundLower,
  className,
  alt,
}: PictureComponentProps) => {
  const imageClassNames = classNames(className, styles.backgroundImage, {
    [styles.moveBackgroundLower]: moveBackgroundLower,
  });

  const sourcesForPicture = generateSourcesForPicture(sources);

  return (
    <Picture
      alt={alt}
      className={imageClassNames}
      sources={sourcesForPicture}
    />
  );
};
