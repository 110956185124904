import { Modal } from '@farmersdog/corgi';
import { Text, Divider, Accordion } from '@farmersdog/corgi-x';

import { useGetLead } from '../../graphql/queries';
import { useGlobalLightbox, LightboxId } from '../../hooks';

import { CopyButtonGroup } from './CopyButtonGroup';
import { useHotKeys } from './hooks';
import styles from './TosaDevTools.module.css';

export function TosaDevToolsNonBlueprint() {
  const lightboxControl = useGlobalLightbox({
    id: LightboxId.TosaDevTools,
  });
  useHotKeys();

  const { data: leadData } = useGetLead();
  const leadEmail = leadData?.lead?.email;
  const userId = leadData?.lead?.corePostgresUserId;

  return (
    <Modal {...lightboxControl}>
      <Text variant="heading-28" bold color="kale-3">
        TOSA Developer Tools 🐶
      </Text>
      <Divider color="Charcoal0" className={styles.divider} />
      <CopyButtonGroup leadEmail={leadEmail} userId={userId} />
      <Accordion
        rows={[
          {
            title: 'LeadView',
            content: (
              <Text variant="heading-16">
                <pre>{JSON.stringify(leadData?.lead, null, 2)}</pre>
              </Text>
            ),
          },
        ]}
      />
    </Modal>
  );
}
