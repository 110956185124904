import { mountVendorWindowEvent } from '../../mountVendorWindowEvent';

import { AppLovinMethod } from './types';

import type { AppLovinEvent, AppLovinTagMounter } from './types';

export interface TrackAppLovinEventArgs {
  name: AppLovinEvent;
  properties?: Record<string, unknown>;
}

declare global {
  interface Window {
    axon?: AppLovinTagMounter;
  }
}

function _mountAppLovinEvent(
  w: Window,
  { name, properties = {} }: TrackAppLovinEventArgs
) {
  // TODO update based on primary tracking method
  w.axon?.(AppLovinMethod.Track, name, properties);
}

export const mountAppLovinEvent = mountVendorWindowEvent(
  'AppLovin',
  _mountAppLovinEvent
);
