import type { QuotePet } from './types';

/**
 * Compare the trial and regular order breakdowns and returns
 * true if subtotal and trialSubtotal amounts are equal for
 * the order and all pets
 */
export function doTrialAndRegularSubtotalsMatch(
  trialTotal: number,
  regularTotal: number,
  petPrices: QuotePet[]
) {
  return (
    trialTotal === regularTotal &&
    petPrices.every(pet => pet.trialSubtotal === pet.regularSubtotal)
  );
}
