import { isbot } from 'isbot';

import type { ReviewsPageRedesignTreatments } from 'src/abTesting';
import { useSsrFeature } from 'src/abTesting';
import { AC_REVIEWS_PAGE_REDESIGN } from 'src/abTesting/features';
import type { ActiveReviewsPageRedesignTreatment } from './types';
import { activeReviewsPageRedesignTreatments } from './types';

export interface UseShowReviewsPageRedesignReturnType {
  shouldShowReviewsPageRedesign: boolean;
  treatment: ReviewsPageRedesignTreatments;
}

export const useShowReviewsPageRedesign =
  (): UseShowReviewsPageRedesignReturnType => {
    const isBot = isbot(navigator.userAgent);
    const { treatment } = useSsrFeature<
      typeof AC_REVIEWS_PAGE_REDESIGN,
      ReviewsPageRedesignTreatments
    >({ featureKey: AC_REVIEWS_PAGE_REDESIGN, attributes: { isBot } });

    const shouldShowReviewsPageRedesign = treatment
      ? activeReviewsPageRedesignTreatments.includes(
          treatment as ActiveReviewsPageRedesignTreatment
        )
      : false;

    return {
      shouldShowReviewsPageRedesign,
      treatment,
    };
  };
