import { Picture, generateSourcesForPicture } from '@farmersdog/corgi-x';

import styles from './FreshUpsell.module.scss';
import { imageSources } from './assets';

export function CardImage() {
  const sourcesForPicture = generateSourcesForPicture(imageSources);

  return (
    <div>
      <Picture
        sources={sourcesForPicture}
        alt="dog next to bowl of food"
        className={styles.image}
      />
    </div>
  );
}
