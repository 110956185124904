import { Text, Grid, Section } from '@farmersdog/corgi-x';
import { paths } from '@farmersdog/constants';

import { currentFormatHeroVariantSources } from './assets';

import { ExtendedButton } from '../../../ExtendedButton';
import { FullScreenImageBackground } from 'src/pages/Home/components/HomepageHero/components/StaticHero/MetaHero';

import { useTrackedSectionRef } from '../../../../../../hooks/useTrackedSections';
import { CtaTrackerV2, HomepageRedesignSections } from '../../../CtaTrackerV2';
import { HeroIconItemsMobile } from '../../components';

import styles from './CurrentFormatHero.module.css';

export const CurrentFormatHero = () => {
  const sectionRef = useTrackedSectionRef(HomepageRedesignSections.Hero);

  return (
    <>
      <Section
        sectionRef={sectionRef}
        aria-label="Homepage Hero"
        className={styles.pageHeroWrapper}
      >
        <Grid
          className={styles.grid}
          justifyContent="center"
          alignItems={{ xs: 'center', lg: 'flex-start' }}
        >
          <div className={styles.content}>
            <Text
              bold
              as="h2"
              color="white"
              topSpacing="none"
              bottomSpacing={{ xs: 'sm', lg: 'md' }}
              className={styles.title}
            >
              The recipe for a
              <br />
              long, healthy life.
            </Text>
            <Text
              as="p"
              weight="semibold"
              topSpacing="none"
              color="white"
              className={styles.subtitle}
              bottomSpacing={{
                xs: 'md',
                lg: 'xl',
              }}
            >
              Made by Board-Certified Nutritionists.
              <br />
              Plans tailored just for your dog.
            </Text>
            <CtaTrackerV2
              type="select"
              moduleLevel={1}
              moduleName={HomepageRedesignSections.Hero}
            >
              <ExtendedButton
                type="link"
                to={paths.PATH_SIGNUP}
                variant="solid-carrot"
              >
                See Plans and Pricing
              </ExtendedButton>
            </CtaTrackerV2>
          </div>
          <FullScreenImageBackground
            className={styles.heroImage}
            sources={{
              mobileSources: currentFormatHeroVariantSources.mobileSources,
              desktopSources: currentFormatHeroVariantSources.desktopSources,
            }}
            alt="Two fluffy white dogs playing in a sunlit garden"
          />
        </Grid>
      </Section>
      <HeroIconItemsMobile />
    </>
  );
};
