import { Grid, GridItem, Text } from '@farmersdog/corgi-x';

import { Card } from './Card';
import styles from './CardRadioGroup.module.css';

export type Value = string | number;

export interface SelectionOption<ValueType extends Value> {
  /** heading text for the option **/
  heading: string;
  /** value that is set in form state when option is selected **/
  value: ValueType;
  /** extended description of the option **/
  description?: string;
  /** source for image in selection card */
  imageSources: string[];
  /** alt text for image in selection card */
  imageAlt: string;
}

export interface CardRadioGroupProps<ValueType extends Value> {
  name: string;
  options?: SelectionOption<ValueType>[];
  currentValue: ValueType | null;
  onChange: (value: ValueType) => void;
  groupDescription?: string;
  imageClassname?: string;
}

export function CardRadioGroup<ValueType extends Value>({
  name,
  options,
  currentValue,
  onChange,
  groupDescription,
  imageClassname,
}: CardRadioGroupProps<ValueType>) {
  if (!options || !options.length) {
    return null;
  }

  return (
    <>
      <Text
        aria-hidden
        variant="article-16"
        color="Carrot2"
        topSpacing="lg"
        bottomSpacing="md"
        fontStyle="italic"
        className={styles.groupDescription}
      >
        {groupDescription}
      </Text>
      <Grid
        flexDirection="row"
        rowGap="sm"
        columnGap={{ lg: 'md' }}
        className={styles.container}
        role="radiogroup"
      >
        {options.map(
          ({ heading, value, description, imageSources, imageAlt }) => {
            return (
              <GridItem key={heading} lg={3} xs={12}>
                <Card
                  name={name}
                  value={value}
                  heading={heading}
                  description={description}
                  imageSources={imageSources}
                  imageAlt={imageAlt}
                  imageClassname={imageClassname}
                  isSelected={currentValue === value}
                  onChange={() => onChange(value)}
                />
              </GridItem>
            );
          }
        )}
      </Grid>
    </>
  );
}
