import type { MobileAndDesktopSources } from '@farmersdog/corgi-x';
import { Logger } from '@farmersdog/logger';

import { HomepageEndToEndRedesignTreatments } from 'src/abTesting';
import {
  activeHomepageRedesignTreatments,
  type ActiveHomepageRedesignTreatment,
} from 'src/hooks/useHomepageRedesign';
import { heroCurrentDogVariantSources } from 'src/pages/HomepageRedesign/components/HeroSection/variants/CurrentDogHero/assets';
import { heroOptimizedVariantSources } from 'src/pages/HomepageRedesign/components/HeroSection/variants/OptimizedHero/assets';
import { currentFormatHeroVariantSources } from 'src/pages/HomepageRedesign/components/HeroSection/variants/CurrentFormatHero/assets';
import { heroOlsVariantSources } from 'src/pages/HomepageRedesign/components/HeroSection/variants/OlsHero/assets';
import { heroMillieVariantSources } from 'src/pages/HomepageRedesign/components/HeroSection/variants/MillieHero/assets';

const OFF_TREATMENTS = [
  HomepageEndToEndRedesignTreatments.Off,
  HomepageEndToEndRedesignTreatments.Control,
];

export const homepageRedesignTreatmentToAssetsMap: Record<
  ActiveHomepageRedesignTreatment,
  MobileAndDesktopSources
> = {
  [HomepageEndToEndRedesignTreatments.CurrentHeroDog]:
    heroCurrentDogVariantSources,
  [HomepageEndToEndRedesignTreatments.OptimizedHero]:
    heroOptimizedVariantSources,
  [HomepageEndToEndRedesignTreatments.CurrentFormat]:
    currentFormatHeroVariantSources,
  [HomepageEndToEndRedesignTreatments.Ols]: heroOlsVariantSources,
  [HomepageEndToEndRedesignTreatments.Millie]: heroMillieVariantSources,
};

const DEFAULT_ASSETS =
  homepageRedesignTreatmentToAssetsMap[
    HomepageEndToEndRedesignTreatments.CurrentHeroDog
  ];

export function getHomepageRedesignAssets(
  treatment: string
): MobileAndDesktopSources {
  const assets =
    homepageRedesignTreatmentToAssetsMap[
      treatment as ActiveHomepageRedesignTreatment
    ];

  if (
    !assets &&
    !OFF_TREATMENTS.includes(treatment as HomepageEndToEndRedesignTreatments)
  ) {
    const log = new Logger('website:homepageRedesignAssets');
    log.error('Homepage redesign treatment not recognized', {
      treatment,
    });
  }

  return assets ?? DEFAULT_ASSETS;
}

export function isActiveHomepageRedesignTreatment(
  treatment: string
): treatment is ActiveHomepageRedesignTreatment {
  return activeHomepageRedesignTreatments.includes(
    treatment as ActiveHomepageRedesignTreatment
  );
}
