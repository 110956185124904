import type { Variants, Transition } from 'motion/react';

export const makeRoomTransition: Transition = {
  type: 'spring',
  stiffness: 100,
  damping: 15,
  delay: 1,
};

export const makeRoomVariants: Variants = {
  initial: { width: 1440 },
  animateLG: { width: 780 },
  animateXL: { width: 900 },
};
