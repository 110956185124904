import { reporter } from 'src/services/reporter';
import { getFacebookPixelAttributes } from 'src/vendors/facebook';
import { trackRadioCheckoutConversion } from 'src/vendors/radio';
import { trackPinterestCheckoutEvent } from 'src/vendors/pinterest';
import {
  TIKTOK_LEGACY_CHECKOUT_EVENT,
  TIKTOK_CHECKOUT_EVENT,
  trackTikTokEvent,
  TikTokCheckoutData,
} from 'src/vendors/tiktok';
import { trackNextDoorConversionEvent } from 'src/vendors/nextDoor';
import { trackBarometricConversionEvent } from 'src/vendors/barometric';
import {
  mountAppLovinPurchase,
  mountRedditPurchase,
  mountSnapchatPurchase,
} from '@farmersdog/pixels';
import {
  trackAudacyCheckoutConversion,
  trackIHeartRadioCheckoutConversion,
} from '../../vendors/claritas';
import { trackArtsAiCheckoutConversion } from 'src/vendors/artsAi';
import { trackAmazonTagCheckout } from 'src/vendors/amazon';
import { trackISpotCheckoutConversion } from 'src/vendors/iSpot';
import {
  trackGa4CheckoutSuccess,
  getRecipeNamesFromPlans,
  getGa4ItemsFromRecipes,
} from 'src/vendors/ga4';
import { trackGoogleAdsCheckoutSuccess } from 'src/vendors/googleAds';
import { trackXEvent, X_Events } from 'src/vendors/x';
import { type UseTrialStartDatesReturn } from 'src/pages/SignupCheckoutPage/hooks/useTrialStartDates';
import { differenceInWeeks } from 'date-fns';

import events from '../eventNames';
import track from '../track';
import getPreTaxTotal from '../getPreTaxTotal';

import { SubscriptionType } from 'src/graphql/types';

import { cookie } from '@farmersdog/lead-browser-storage';
import { trackAspireIqCheckoutConversion } from 'src/vendors/aspireIq';
import { PEACOCK_EVENTS, trackPeacockEvent } from 'src/vendors/peacock';
import { trackPostieCheckout } from 'src/vendors/postie';
import { trackCheckoutDailyPriceAndFrequency } from './trackCheckoutDailyPriceAndFrequency';
import { trackFluentTrialConversion } from 'src/vendors/fluent';
import { trackKatzCheckoutConversion } from 'src/vendors/katz';
import { trackSpotifyPurchase } from 'src/vendors/spotify';

interface TrackCheckoutSuccessArgs {
  user?: Reducer.User;
  subtotalAmount: number;
  orders?: Reducer.Order[];
  configuration?: Record<string, unknown>;
  requestAttributes?: Record<string, unknown>;
  anonymousId?: string;
  discountOverride?: number | null;
  selectedStartWeek: UseTrialStartDatesReturn['selectedStartDate'];
  earliestAvailableStartWeek: string | undefined;
  regularDailyPrice: number | undefined;
}

/**
 * A segment tracking event that is used to mark a customer successfully
 * checking out.
 *
 * @param user - A TFD user object
 * @param subtotalAmount - The subtotal amount of the Fresh quote.
 * @param configuration - The configuration object stored in redux.
 * @param requestAttributes - Freeform attributes to submit to segment.
 * @param orders - a list of customer orders
 * @param selectedStartDate - User-selected start week
 * @param earliestAvailableStartWeek - Earliest available start week option
 */
export function trackCheckoutSuccess({
  user,
  subtotalAmount,
  configuration,
  requestAttributes,
  discountOverride,
  selectedStartWeek,
  earliestAvailableStartWeek,
  regularDailyPrice,
}: TrackCheckoutSuccessArgs) {
  if (!user) {
    reporter.warn(
      `Segment event "${events.checkout_success}" has missing parameter "user"`
    );
  }

  if (!configuration) {
    reporter.warn(
      `Segment event "${events.checkout_success}" has missing parameter "configuration"`
    );
  }

  const value = getPreTaxTotal(subtotalAmount);
  const foodPlans = user?.subscription?.foodPlans ?? [];
  const ga4Items = getGa4ItemsFromRecipes(getRecipeNamesFromPlans(user));

  // * 👇 This is the offset in weeks between the user-chosen week and the earliest available start week. For ex., if the earliest week is this week but the user chose 2 weeks from now to start, selectedStartWeekOffset would be 2. selectedStartWeek of `null` means the user opted for ASAP.
  const selectedStartWeekOffset =
    earliestAvailableStartWeek && selectedStartWeek
      ? differenceInWeeks(selectedStartWeek, earliestAvailableStartWeek)
      : 0;

  const userIdString = user?.id ? String(user.id) : '';

  // If you update the payload here, please update the tracking plan in Segment
  // for this event as well:
  // https://app.segment.com/farmersdog/protocols/tracking-plans/tp_1eST2xLpVi5ut60vM5sYE9MsYa1/draft?name=Checkout+-+Success
  track(events.checkout_success, {
    selectedStartWeek,
    earliestAvailableStartWeek,
    selectedStartWeekOffset,
    discountOverride,
    mixingPlan: foodPlans.some(plan => plan.ratio !== 1),
    currency: 'USD',
    value,
    // @ts-expect-error User can be undefined
    ...getFacebookPixelAttributes(user, configuration),
    ...requestAttributes,
    phone: user?.phone,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    fbp: cookie.getFacebookPixelCookie(),
    fcp: cookie.getFacebookClickIdCookie(),
    product_line: SubscriptionType.Fresh,
    label: SubscriptionType.Fresh,
    oid: user?.id,
    city: user?.shippingAddress?.city?.toLowerCase(),
    state: user?.shippingAddress?.state?.abbr.toLowerCase(),
    zip: user?.shippingAddress?.zip,
  });

  trackCheckoutDailyPriceAndFrequency({
    regularDailyPrice,
    frequency: user?.subscription?.frequency,
  });
  // @ts-expect-error User can be undefined
  trackRadioCheckoutConversion({ userId: user?.id });
  // @ts-expect-error User can be undefined
  void trackPinterestCheckoutEvent({ userId: user?.id, email: user?.email });
  // TODO - remove legacy checkout event; currently supporting two concurrent checkout events
  // https://app.shortcut.com/farmersdog/story/119078/remove-legacy-tiktok-checkout-event
  void trackTikTokEvent(TIKTOK_LEGACY_CHECKOUT_EVENT, {
    email: user?.email,
    phone: user?.phone,
    event_id: userIdString,
    value,
    currency: TikTokCheckoutData.currency,
  });
  void trackTikTokEvent(TIKTOK_CHECKOUT_EVENT, {
    email: user?.email,
    phone: user?.phone,
    event_id: userIdString,
    value,
    currency: TikTokCheckoutData.currency,
  });
  void trackNextDoorConversionEvent(user?.email);
  void mountSnapchatPurchase({ email: user?.email, transactionId: user?.id });
  mountRedditPurchase({ eventId: userIdString });
  mountAppLovinPurchase({ transactionId: userIdString });
  // @ts-expect-error User can be undefined
  trackBarometricConversionEvent(user?.id);
  trackISpotCheckoutConversion({
    utmSource: requestAttributes?.utm_source,
  });
  trackAudacyCheckoutConversion();
  trackIHeartRadioCheckoutConversion();
  trackArtsAiCheckoutConversion();
  trackKatzCheckoutConversion();
  trackXEvent({ eventName: X_Events.Purchase });
  trackAmazonTagCheckout({ userId: user?.id, orderId: user?.id });
  trackGa4CheckoutSuccess({
    userId: user?.id,
    items: ga4Items,
    productLine: SubscriptionType.Fresh,
    value,
  });
  trackGoogleAdsCheckoutSuccess({ userId: user?.id, value });
  trackAspireIqCheckoutConversion();
  trackPeacockEvent({ eventName: PEACOCK_EVENTS.PURCHASE });
  trackPostieCheckout({ user });
  trackFluentTrialConversion({ email: user?.email });
  trackSpotifyPurchase(userIdString);
}
