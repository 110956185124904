import { useEffect } from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import qs from 'qs';

import { page } from 'src/analytics';
import {
  PATH_HOME,
  PATH_SIGNUP_CHECKOUT,
  PATH_SIGNUP_RECIPES,
} from '@farmersdog/constants/paths';

import { useTrackAmazonTagPageView } from 'src/vendors/amazon';
import { useTrackRadioPageView } from 'src/vendors/radio';
import { useTrackPinterestPageView } from 'src/vendors/pinterest';
import { useTrackNextDoorPageView } from 'src/vendors/nextDoor';
import { useTrackBarometricPageView } from 'src/vendors/barometric';
import { useTrackISpotPageView } from 'src/vendors/iSpot';
import { useTrackGa4PageView } from 'src/vendors/ga4';
import {
  useTrackAudacyPageView,
  useTrackIHeartRadioPageView,
} from 'src/vendors/claritas';
import { useTrackArtsAiPageView } from 'src/vendors/artsAi';

import { isDuplicatePage, setPageCache } from './preventDuplicatePage';
import { isDiySignup } from '@farmersdog/lead-browser-storage';
import { useTrackTikTokPageView } from 'src/vendors/tiktok';
import { useTrackPeacockPageView } from 'src/vendors/peacock';

import { shouldTrack } from './shouldTrack';
import { useTrackPostiePageView } from 'src/vendors/postie';
import { useTrackKatzPageView } from 'src/vendors/katz';
import { trackSpotifyPageView } from 'src/vendors/spotify';
import {
  mountAppLovinPageView,
  mountRedditPageVisit,
  mountSnapchatPageView,
} from '@farmersdog/pixels';
import { getReferralCode } from './getReferralCode';
import { useFeature } from 'src/abTesting/useFeature';
import { AC_REFERRALS_PERSIST_CODE } from 'src/abTesting/features';
import { filterQueryParameters } from './filterQueryParameters';

// This is the list of query parameters that we should remove from the address
// bar so they are not visible
const QUERY_PARAMS_TO_REMOVE = ['c'];

// This is the list of pathnames we don't want to fire page view events for
const DO_NOT_TRACK: string[] = [];

const SNAPCHAT_PAGEVIEW_ROUTES = [PATH_HOME, PATH_SIGNUP_CHECKOUT];
const REDDIT_PAGEVIEW_ROUTES = [PATH_HOME, PATH_SIGNUP_CHECKOUT];
const APPLOVIN_PAGEVIEW_ROUTES = [PATH_HOME, PATH_SIGNUP_CHECKOUT];

/**
 * Execute a page view event whenever the location changes.
 */
function usePageViewTracker() {
  const location = useLocation();
  const history = useHistory();
  const isRecipesPage = Boolean(useRouteMatch(PATH_SIGNUP_RECIPES));

  const showTosaRecipesPage = !isDiySignup();

  const { pathname, search } = location;

  const referralCode = getReferralCode(search);
  const { treatment, isReady } = useFeature(AC_REFERRALS_PERSIST_CODE, {
    attributes: { referralCode },
  });

  useTrackRadioPageView();
  useTrackPeacockPageView();
  useTrackBarometricPageView();
  useTrackISpotPageView();
  useTrackAudacyPageView();
  useTrackIHeartRadioPageView();
  useTrackArtsAiPageView();
  useTrackKatzPageView();
  useTrackPinterestPageView({ isRecipesPage, showTosaRecipesPage });
  useTrackNextDoorPageView({ isRecipesPage, showTosaRecipesPage });
  useTrackAmazonTagPageView({ isRecipesPage, showTosaRecipesPage });
  useTrackGa4PageView({ isRecipesPage, showTosaRecipesPage });
  useTrackTikTokPageView({ isRecipesPage, showTosaRecipesPage });
  useTrackPostiePageView();

  useEffect(() => {
    if (
      shouldTrack({ path: pathname, blacklist: DO_NOT_TRACK }) &&
      !isDuplicatePage({ pathname, search })
    ) {
      page(pathname, {
        search,
        title: document.title,
      });
      setPageCache({ pathname, search });
    }
  }, [pathname, search]);

  // this effect is for pixels that should only fire pageview events when the pathname changes (not query parameters)
  useEffect(() => {
    trackSpotifyPageView(pathname);
    if (SNAPCHAT_PAGEVIEW_ROUTES.includes(pathname)) {
      mountSnapchatPageView();
    }
    if (REDDIT_PAGEVIEW_ROUTES.includes(pathname)) {
      mountRedditPageVisit();
    }
    if (APPLOVIN_PAGEVIEW_ROUTES.includes(pathname)) {
      mountAppLovinPageView();
    }
  }, [pathname]);

  useEffect(() => {
    const query = qs.parse(search, { ignoreQueryPrefix: true });

    const filteredSearch = qs.stringify(query, {
      filter: (param, value: string) =>
        QUERY_PARAMS_TO_REMOVE.includes(param) ? undefined : value,
      addQueryPrefix: true,
    });

    if (filteredSearch !== search) {
      history.replace(`${pathname}${filteredSearch || ''}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (referralCode === '' || !isReady || treatment === 'on') {
      return;
    }

    const finalSearch = filterQueryParameters({ search, params: ['ref'] });

    if (finalSearch !== search) {
      history.replace(`${pathname}${finalSearch}`);
    }
  }, [referralCode, treatment, isReady, pathname, search, history]);
}

export default usePageViewTracker;
