import classNames from 'classnames';
import { Text, Nowrap, Grid } from '@farmersdog/corgi-x';
import { getLinkToFreshSignup } from '@farmersdog/tosa/src/utils/getLinkToSignupFlow';
import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import { useShowHomepageRedesign } from 'src/hooks/useHomepageRedesign';
import { HomepageEndToEndRedesignTreatments } from 'src/abTesting';

import { ExtendedText, ExtendedButton } from '../../../../components';
import { CtaTrackerV2, HomepageRedesignSections } from '../../../CtaTrackerV2';

import styles from './HeroTitle.module.css';

const ctaLabel = 'See Plans and Pricing';

const defaultTitle = (
  <>
    The recipe for a
    <br className={styles.titeBreakline} /> long, <Nowrap>healthy life.</Nowrap>
  </>
);

const defaultSubtitle = (
  <>
    Made by Board-Certified Nutritionists.{' '}
    <br className={styles.subtitleBreakline} />
    Plans tailored just <Nowrap>for your dog.</Nowrap>
  </>
);

const altTitle = (
  <>
    Supported by vets. <br className={styles.subtitleBreakline} />
    Loved by <Nowrap>dog people.</Nowrap>
  </>
);

const altSubtitle = (
  <>
    Gently cooked fresh food formulated by{' '}
    <br className={styles.subtitleBreakline} />
    board-certified nutritionists.
  </>
);

type ContentByTreatmentMap = Partial<
  Record<HomepageEndToEndRedesignTreatments, JSX.Element>
>;

const titleFromTreatmentsMap: ContentByTreatmentMap = {
  [HomepageEndToEndRedesignTreatments.Ols]: altTitle,
  [HomepageEndToEndRedesignTreatments.Millie]: altTitle,
};

const subtitleFromTreatmentsMap: ContentByTreatmentMap = {
  [HomepageEndToEndRedesignTreatments.Ols]: altSubtitle,
  [HomepageEndToEndRedesignTreatments.Millie]: altSubtitle,
};

export const HeroTitle = () => {
  const { treatment } = useShowHomepageRedesign();
  const linkToFreshSignup = getLinkToFreshSignup(PATH_SIGNUP_ME);

  const title = titleFromTreatmentsMap[treatment] ?? defaultTitle;
  const subtitle = subtitleFromTreatmentsMap[treatment] ?? defaultSubtitle;

  return (
    <Grid className={styles.heroTitleContainer}>
      <div>
        <ExtendedText
          color="Kale3"
          as="h1"
          className={classNames(styles.heroTitle, styles[treatment])}
        >
          {title}
        </ExtendedText>
        <Text
          as="p"
          className={styles.heroSubtitle}
          color="Kale3"
          align="center"
          vSpacing="none"
          weight="semibold"
        >
          {subtitle}
        </Text>
      </div>
      <div>
        <CtaTrackerV2
          type="select"
          moduleLevel={1}
          moduleName={HomepageRedesignSections.Hero}
        >
          <ExtendedButton
            variant="solid-carrot"
            type="link"
            to={linkToFreshSignup}
            className={styles.heroCta}
          >
            {ctaLabel}
          </ExtendedButton>
        </CtaTrackerV2>
      </div>
    </Grid>
  );
};
