import { useRef } from 'react';

import { NodeNames } from '@farmersdog/constants';

import { trackChangedInputSelection } from '../../analytics';
import { getNodeNameAndPosition } from '../../blueprint/utils';
import { getCompletedData } from '../../utils';
import { BranchNode } from '../schema/BranchNode';

import type { BranchNode as BranchNodeType } from '../../blueprint/types';
import type { TOSAComponentInput } from '../../types';

// These are the names of forms that have inputs that we want to track leads'
// changes to when they submit the form.
const FORM_NAME_TO_INPUT_NAME = new Map([
  ['PetActivity', NodeNames.ActivityLevel],
  ['PetPicky', NodeNames.EatingStyle],
] as const);

export function Form(props: TOSAComponentInput<BranchNodeType>) {
  const { formMethods, node, onSubmit, progress } = props;
  const inputNameToTrack = getInputNameFromFormName(node.name);
  const initialValue = useRef(
    inputNameToTrack && formMethods.getValues(inputNameToTrack)
  );

  const handleSubmit = formMethods.handleSubmit(async (formData, e) => {
    const completedData = getCompletedData({
      data: formData,
      completedLeafNodes: progress.getCompletedLeafNodes(),
    });

    if (inputNameToTrack) {
      const currentValue = completedData[inputNameToTrack];
      if (
        initialValue.current &&
        currentValue &&
        initialValue.current !== currentValue
      ) {
        trackChangedInputSelection({
          previousSelection: initialValue.current,
          newSelection: currentValue,
        });
      }
    }

    await onSubmit?.(completedData, e);
  });

  return (
    <form id={node.name} onSubmit={handleSubmit}>
      <BranchNode {...props} parentFormId={node.name} />
    </form>
  );
}

function getInputNameFromFormName(formName: string) {
  const { name, position } = getNodeNameAndPosition(formName);
  const inputName = FORM_NAME_TO_INPUT_NAME.get(
    name as string as 'PetActivity' | 'PetPicky'
  );

  if (!inputName) {
    return;
  }

  return `${inputName}-${position}` as const;
}
