import { useShowHomepageRedesign } from 'src/hooks/useHomepageRedesign';
import { HomepageEndToEndRedesignTreatments } from 'src/abTesting';
import {
  CurrentDogHero,
  OptimizedHero,
  CurrentFormatHero,
  OlsHero,
  MillieHero,
} from './variants';

const heroVariantsMap: Partial<
  Record<HomepageEndToEndRedesignTreatments, JSX.Element>
> = {
  [HomepageEndToEndRedesignTreatments.CurrentHeroDog]: <CurrentDogHero />,
  [HomepageEndToEndRedesignTreatments.OptimizedHero]: <OptimizedHero />,
  [HomepageEndToEndRedesignTreatments.CurrentFormat]: <CurrentFormatHero />,
  [HomepageEndToEndRedesignTreatments.Ols]: <OlsHero />,
  [HomepageEndToEndRedesignTreatments.Millie]: <MillieHero />,
};

const defaultVariant = <CurrentDogHero />;

export const HeroSection = () => {
  const { treatment } = useShowHomepageRedesign();

  return heroVariantsMap[treatment] ?? defaultVariant;
};
