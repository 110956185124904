import { useRef } from 'react';
import { Helmet } from 'react-helmet-async';

import { Navigation } from 'src/components/Navigation';

import schema from '../Home/schema.json';

import {
  HeroIconItemsDesktop,
  HeroSection,
  HomepageContent,
  OrderNowMarquee,
} from './components';

import styles from './HomepageRedesign.module.css';

export const HomepageRedesign = () => {
  const ctaReveal = useRef(null);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <div className={styles.pageBackground}>
        <Navigation backgroundColor="chickpea" ctaTrigger={ctaReveal} />
        <HeroSection />
        <div ref={ctaReveal}>
          <HeroIconItemsDesktop />
          <OrderNowMarquee />
          <HomepageContent />
        </div>
      </div>
    </>
  );
};
