import { Grid, Section } from '@farmersdog/corgi-x';

import { HeroIconItemsMobile, HeroImage, HeroTitle } from '../../components';
import { useTrackedSectionRef } from '../../../../../../hooks/useTrackedSections';
import { HomepageRedesignSections } from '../../../CtaTrackerV2';

import { heroMillieVariantSources } from './assets';

import styles from './MillieHero.module.css';

export const MillieHero = () => {
  const sectionRef = useTrackedSectionRef(HomepageRedesignSections.Hero);

  return (
    <>
      <Section
        aria-label="Homepage Hero"
        hSpacing={{ xs: 'md', md: 'xxl' }}
        className={styles.pageHeroWrapper}
        sectionRef={sectionRef}
      >
        <Grid className={styles.heroContent}>
          <HeroTitle />
          <HeroIconItemsMobile />
        </Grid>
      </Section>
      <HeroImage objectPosition="100% 20%" sources={heroMillieVariantSources} />
    </>
  );
};
