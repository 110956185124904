import HeroOlsVariantDesktopJpg from './hero-ols-desktop.jpg';
import HeroOlsVariantDesktopWebp from './hero-ols-desktop.webp';
import HeroOlsVariantMobileJpg from './hero-ols-mobile.jpg';
import HeroOlsVariantMobileWebp from './hero-ols-mobile.webp';

export const heroOlsVariantSources = {
  mobileSources: {
    webP: HeroOlsVariantMobileWebp,
    jpg: HeroOlsVariantMobileJpg,
  },
  desktopSources: {
    webP: HeroOlsVariantDesktopWebp,
    jpg: HeroOlsVariantDesktopJpg,
  },
};
