export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  JSON: { input: Record<string, unknown>; output: Record<string, unknown> };
  JSONObject: {
    input: Record<string, unknown>;
    output: Record<string, unknown>;
  };
};

export type ActivityLevel = {
  __typename?: 'ActivityLevel';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AvailableProductsView = {
  __typename?: 'AvailableProductsView';
  treats: Array<Treat>;
};

export type AvailableProductsViewTreatsArgs = {
  discountCode: InputMaybe<Scalars['String']['input']>;
  discountType: InputMaybe<DiscountType>;
};

export type AvailableRecipe = {
  __typename?: 'AvailableRecipe';
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: AvailableRecipeType;
};

export enum AvailableRecipeType {
  Food = 'food',
  Treat = 'treat',
}

export type BirthdayInput = {
  /** the amount of time expressed by the associated unit */
  amount: Scalars['Int']['input'];
  /** unit of time represented by the associated amount */
  unit: Scalars['String']['input'];
};

export type BirthdayView = {
  __typename?: 'BirthdayView';
  /** the amount of time expressed by the associated unit */
  amount: Scalars['Int']['output'];
  /** the time at which the birthday was recorded expressed as a date time scalar */
  referencedAt: Scalars['DateTime']['output'];
  /** unit of time represented by the associated amount */
  unit: Scalars['String']['output'];
};

export type BodyCondition = {
  __typename?: 'BodyCondition';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum BoxProgressionStrategy {
  DefaultPlanFrequency = 'defaultPlanFrequency',
  Legacy = 'legacy',
}

export type Breed = {
  __typename?: 'Breed';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  weightMax: Maybe<Scalars['Int']['output']>;
};

export type ConsentView = {
  __typename?: 'ConsentView';
  dnss: Maybe<Scalars['Boolean']['output']>;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['Int']['output'];
  iso: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  states: Maybe<Array<State>>;
};

export type CreateLeadResponse = {
  __typename?: 'CreateLeadResponse';
  /** indicates whether lead can recover answers from a previous signup */
  recoverable: Scalars['Boolean']['output'];
  /** authorization token (or null if lead is not authorized) */
  token: Maybe<Scalars['String']['output']>;
};

export type DiscountInput = {
  associatedAt: Scalars['DateTime']['input'];
  code: Scalars['String']['input'];
  type: DiscountType;
};

export enum DiscountType {
  Partner = 'partner',
  User = 'user',
}

export enum DiscountTypeEnum {
  Coupon = 'coupon',
  Referral = 'referral',
}

export type DiscountView = {
  __typename?: 'DiscountView';
  associatedAt: Scalars['DateTime']['output'];
  code: Scalars['String']['output'];
  type: DiscountType;
};

export type EatingStyle = {
  __typename?: 'EatingStyle';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type FoodType = {
  __typename?: 'FoodType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type FreshFoodConfidence = {
  __typename?: 'FreshFoodConfidence';
  label: Scalars['String']['output'];
  uid: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type FreshRecipeContent = {
  __typename?: 'FreshRecipeContent';
  calorieContent: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  fullIngredients: Scalars['String']['output'];
  guaranteedAnalysis: Maybe<Scalars['String']['output']>;
  mainIngredients: Scalars['String']['output'];
  name: Scalars['String']['output'];
  productLine: Maybe<Scalars['String']['output']>;
  supplements: Scalars['String']['output'];
  typicalAnalysis: Maybe<Scalars['String']['output']>;
};

export type FreshRecipeRecommendation = {
  __typename?: 'FreshRecipeRecommendation';
  content: FreshRecipeContent;
  name: Scalars['String']['output'];
  recommended: Scalars['Boolean']['output'];
};

export type HealthIssue = {
  __typename?: 'HealthIssue';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  type: HealthIssueType;
};

export enum HealthIssueType {
  Default = 'default',
  User = 'user',
}

export type LeadInput = {
  /** the client-side anonymous ID */
  anonymousId?: InputMaybe<Scalars['String']['input']>;
  /** version of the form blueprint used to generate lead input */
  blueprintVersion: Scalars['String']['input'];
  /** collection of discounts associated with the lead */
  discount?: InputMaybe<DiscountInput>;
  /** first name of the lead */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** how confident the lead is in the benefits of fresh food */
  freshFoodConfidence?: InputMaybe<Scalars['String']['input']>;
  /** collection of pets belonging to the lead */
  pets?: InputMaybe<Array<PetInput>>;
  /** phone number of the lead */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** whether the user has consented to marketing texts & calls */
  phoneConsent?: InputMaybe<Scalars['Boolean']['input']>;
  /** collection of product lines associated with the lead */
  productLine?: InputMaybe<ProductLineInput>;
  /** Lead-level products that the lead has selected to include in their subscription */
  selection?: InputMaybe<LeadSelectionInput>;
  /** shipping zip code of the lead */
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadSelectionInput = {
  treats?: InputMaybe<Array<TreatInput>>;
};

export type LeadSelectionTreat = {
  __typename?: 'LeadSelectionTreat';
  name: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  size: TreatSize;
};

export type LeadSelectionView = {
  __typename?: 'LeadSelectionView';
  /** Treats that the lead has selected to include in their subscription */
  treats: Maybe<Array<LeadSelectionTreat>>;
};

export type LeadView = {
  __typename?: 'LeadView';
  /** anonymous ID of the lead for analytics tracking */
  anonymousId: Maybe<Scalars['String']['output']>;
  /** version of the form blueprint used to generate lead input */
  blueprintVersion: Maybe<Scalars['String']['output']>;
  /** shipping city of the lead */
  city: Maybe<Scalars['String']['output']>;
  /** privacy consent */
  consent: Maybe<ConsentView>;
  /** id of the user in the core postgres database */
  corePostgresUserId: Maybe<Scalars['Int']['output']>;
  /** UTC time (down to seconds) at which the lead was created */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** collection of discounts associated with the lead */
  discount: Maybe<DiscountView>;
  /** email address of the lead */
  email: Scalars['String']['output'];
  /** first name of the lead */
  firstName: Maybe<Scalars['String']['output']>;
  /** how confident the lead is in the benefits of fresh food */
  freshFoodConfidence: Maybe<Scalars['String']['output']>;
  /** Human ID of the user. Is immutable. */
  humanId: Maybe<Scalars['String']['output']>;
  /** collection of pets belonging to the lead. */
  pets: Array<PetView>;
  /** whether the user has consented to marketing texts & calls */
  phoneConsent: Maybe<Scalars['Boolean']['output']>;
  /** collection of product lines associated with the lead */
  productLine: Maybe<ProductLineView>;
  /** Lead-level products that the lead has selected to include in their subscription */
  selection: Maybe<LeadSelectionView>;
  /** shipping state of the lead */
  state: Maybe<Scalars['String']['output']>;
  /** indicates whether the user had lpf_eligible or lpf_recommended */
  temporaryGrainsDiscount: Maybe<Scalars['Boolean']['output']>;
  /** shipping zip code of the lead */
  zip: Maybe<Scalars['String']['output']>;
};

export enum MassUnit {
  Grams = 'grams',
  Lbs = 'lbs',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** create a lead or return the authorization token for an existing lead */
  createLead: CreateLeadResponse;
  /** load previously saved lead data and merge with client input */
  recoverLead: LeadView;
  /** register a lead */
  registerLead: RegisterLeadResponse;
  /** sync an existing lead with a user */
  syncLeadWithUser: RegisterLeadResponse;
  /** update attributes of a lead */
  updateLead: LeadView;
  /** sets a user's product eligibility */
  updateUserProductEligibility: UpdateUserProductEligibilityResponse;
};

export type MutationCreateLeadArgs = {
  email: Scalars['String']['input'];
};

export type MutationRecoverLeadArgs = {
  lead: LeadInput;
  units: InputMaybe<UnitInput>;
};

export type MutationRegisterLeadArgs = {
  disableRecipesUpdate: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadInput;
  units: InputMaybe<UnitInput>;
};

export type MutationSyncLeadWithUserArgs = {
  disableRecipesUpdate: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  skipSyncIfNoRecipeSelection: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateLeadArgs = {
  lead: LeadInput;
  units: InputMaybe<UnitInput>;
};

export type MutationUpdateUserProductEligibilityArgs = {
  email: Scalars['String']['input'];
  productLine: UserProductEligibilityProductLine;
  recipeNames: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrderQuote = {
  __typename?: 'OrderQuote';
  breakdown: OrderQuoteBreakdown;
  discount: OrderQuoteDiscount;
  grandTotal: Scalars['Float']['output'];
  planDetails: OrderQuotePlanDetails;
  preTaxDiscountedSubtotal: Scalars['Float']['output'];
  tax: Maybe<Scalars['Float']['output']>;
};

export type OrderQuoteBreakdown = {
  __typename?: 'OrderQuoteBreakdown';
  daily: OrderQuoteDailyBreakdown;
  lineItems: OrderQuoteLineItems;
  weekly: OrderQuoteWeeklyBreakdown;
};

export type OrderQuoteDailyBreakdown = {
  __typename?: 'OrderQuoteDailyBreakdown';
  amount: Scalars['Float']['output'];
  pets: Array<OrderQuotePetDailyBreakdown>;
};

export type OrderQuoteDiscount = {
  __typename?: 'OrderQuoteDiscount';
  /** the total of all discounts applied */
  discountAmount: Scalars['Float']['output'];
  discountPercentage: Scalars['Int']['output'];
};

export type OrderQuoteLineItems = {
  __typename?: 'OrderQuoteLineItems';
  treats: Array<OrderQuoteTreat>;
};

export type OrderQuotePetDailyBreakdown = {
  __typename?: 'OrderQuotePetDailyBreakdown';
  amount: Scalars['Float']['output'];
  discountedAmount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type OrderQuotePlanDetails = {
  __typename?: 'OrderQuotePlanDetails';
  /** how many days of food in the order */
  daysOfFood: Scalars['Float']['output'];
};

export type OrderQuoteTreat = {
  __typename?: 'OrderQuoteTreat';
  amount: Scalars['Float']['output'];
  discountedAmount: Scalars['Float']['output'];
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  size: TreatSize;
};

export type OrderQuoteWeeklyBreakdown = {
  __typename?: 'OrderQuoteWeeklyBreakdown';
  amount: Scalars['Float']['output'];
};

export type PersonalityType = {
  __typename?: 'PersonalityType';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PetInput = {
  /** how active the pet is */
  activityLevel?: InputMaybe<Scalars['String']['input']>;
  /** a representation of the pet's age, expressed in an amount of time and associated unit */
  birthdayInput?: InputMaybe<BirthdayInput>;
  /** how the owner feels their pet's body shape matches its ideal state */
  bodyCondition?: InputMaybe<Scalars['String']['input']>;
  /** breeds of the pet */
  breeds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** a description of the pet's eating style */
  eatingStyle?: InputMaybe<Scalars['String']['input']>;
  /** what brand of food the pet currently eats */
  foodBrand?: InputMaybe<Scalars['String']['input']>;
  /** what category of food the pet currently eats */
  foodType?: InputMaybe<Scalars['String']['input']>;
  /** the pet's gender */
  gender?: InputMaybe<Scalars['String']['input']>;
  /** a list of health issues the pet currently has */
  issues?: InputMaybe<Array<Scalars['String']['input']>>;
  /** name of the pet */
  name: Scalars['String']['input'];
  /** a description of the pet's personality */
  nature?: InputMaybe<Scalars['String']['input']>;
  /** whether the pet is neutered or not */
  neutered?: InputMaybe<Scalars['Boolean']['input']>;
  /** a list of the types of prescription diets the pet requires */
  prescriptionDiets?: InputMaybe<Array<Scalars['String']['input']>>;
  /** the recommended daily calories for the pet to consume */
  recommendedCalories?: InputMaybe<Scalars['Int']['input']>;
  /** a list of product selections associated with the pet */
  selection?: InputMaybe<PetProductSelectionInput>;
  /** target weight of the pet */
  targetWeight?: InputMaybe<Scalars['Float']['input']>;
  /** how frequently the pet eats treats */
  treatsQuantity?: InputMaybe<Scalars['String']['input']>;
  /** current weight of the pet */
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type PetInputForQuote = {
  /** how active the pet is */
  activityLevel?: InputMaybe<Scalars['String']['input']>;
  /** a representation of the pet's age, expressed in an amount of time and associated unit */
  birthdayInput?: InputMaybe<BirthdayInput>;
  /** how the owner feels their pet's body shape matches its ideal state */
  bodyCondition?: InputMaybe<Scalars['String']['input']>;
  /** breeds of the pet */
  breeds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** a description of the pet's eating style */
  eatingStyle?: InputMaybe<Scalars['String']['input']>;
  /** what brand of food the pet currently eats */
  foodBrand?: InputMaybe<Scalars['String']['input']>;
  /** what category of food the pet currently eats */
  foodType?: InputMaybe<Scalars['String']['input']>;
  /** the pet's gender */
  gender?: InputMaybe<Scalars['String']['input']>;
  /** a list of health issues the pet currently has */
  issues?: InputMaybe<Array<Scalars['String']['input']>>;
  /** name of the pet */
  name: Scalars['String']['input'];
  /** a description of the pet's personality */
  nature?: InputMaybe<Scalars['String']['input']>;
  /** whether the pet is neutered or not */
  neutered?: InputMaybe<Scalars['Boolean']['input']>;
  /** a list of the types of prescription diets the pet requires */
  prescriptionDiets?: InputMaybe<Array<Scalars['String']['input']>>;
  /** the calories requested for a pet */
  requestedCalories: Scalars['Int']['input'];
  /** a list of product selections associated with the pet */
  selection: PetProductSelectionInput;
  /** target weight of the pet */
  targetWeight?: InputMaybe<Scalars['Float']['input']>;
  /** how frequently the pet eats treats */
  treatsQuantity?: InputMaybe<Scalars['String']['input']>;
  /** current weight of the pet */
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type PetProductDiySelection = {
  __typename?: 'PetProductDiySelection';
  createdAt: Scalars['DateTime']['output'];
  options: PetProductDiySelectionOptions;
  productLine: ProductLineType;
};

export type PetProductDiySelectionInput = {
  options: PetProductDiySelectionOptionsInput;
  productLine: ProductLineType;
};

export type PetProductDiySelectionOptions = {
  __typename?: 'PetProductDiySelectionOptions';
  cadence: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type PetProductDiySelectionOptionsInput = {
  cadence: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
};

export type PetProductFreshSelection = {
  __typename?: 'PetProductFreshSelection';
  createdAt: Scalars['DateTime']['output'];
  options: PetProductFreshSelectionOptions;
  productLine: ProductLineType;
};

export type PetProductFreshSelectionInput = {
  options: PetProductFreshSelectionOptionsInput;
  productLine: ProductLineType;
};

export type PetProductFreshSelectionOptions = {
  __typename?: 'PetProductFreshSelectionOptions';
  recipes: Array<Scalars['String']['output']>;
};

export type PetProductFreshSelectionOptionsInput = {
  mixingPlanRatio?: InputMaybe<Scalars['Float']['input']>;
  recipes: Array<Scalars['String']['input']>;
};

export type PetProductSelection = {
  __typename?: 'PetProductSelection';
  diy: Maybe<PetProductDiySelection>;
  fresh: Maybe<PetProductFreshSelection>;
};

export type PetProductSelectionInput = {
  diy?: InputMaybe<PetProductDiySelectionInput>;
  fresh?: InputMaybe<PetProductFreshSelectionInput>;
};

export type PetView = {
  __typename?: 'PetView';
  /** how active the pet is */
  activityLevel: Maybe<Scalars['String']['output']>;
  /** a representation of the pet's age, expressed as a date time scalar */
  birthday: Maybe<Scalars['DateTime']['output']>;
  /** a representation of the pet's age, expressed in an amount of time and associated unit */
  birthdayInput: Maybe<BirthdayView>;
  /** how the owner feels their pet's body shape matches its ideal state */
  bodyCondition: Maybe<Scalars['String']['output']>;
  /** breeds of the pet */
  breeds: Array<Scalars['String']['output']>;
  /** a description of the pet's eating style */
  eatingStyle: Maybe<Scalars['String']['output']>;
  /** what brand of food the pet currently eats */
  foodBrand: Maybe<Scalars['String']['output']>;
  /** what category of food the pet currently eats */
  foodType: Maybe<Scalars['String']['output']>;
  /** the pet's gender */
  gender: Maybe<Scalars['String']['output']>;
  /** a list of health issues the pet currently has */
  issues: Array<Scalars['String']['output']>;
  /** name of the pet */
  name: Scalars['String']['output'];
  /** a description of the pet's personality */
  nature: Maybe<Scalars['String']['output']>;
  /** whether the pet is neutered or not */
  neutered: Maybe<Scalars['Boolean']['output']>;
  /** a list of the types of prescription diets the pet requires */
  prescriptionDiets: Array<Scalars['String']['output']>;
  /** the recommended daily calories for the pet to consume */
  recommendedCalories: Maybe<Scalars['Int']['output']>;
  /** a list of product selections associated with the pet */
  selection: Maybe<PetProductSelection>;
  /** target weight of the pet */
  targetWeight: Maybe<Scalars['Float']['output']>;
  /** how frequently the pet eats treats */
  treatsQuantity: Maybe<Scalars['String']['output']>;
  /** current weight of the pet */
  weight: Maybe<Scalars['Float']['output']>;
};

export type PetViewTargetWeightArgs = {
  unit?: InputMaybe<MassUnit>;
};

export type PetViewWeightArgs = {
  unit?: InputMaybe<MassUnit>;
};

export type Plan = {
  __typename?: 'Plan';
  dailyKCal: Scalars['Int']['output'];
  petName: Scalars['String']['output'];
  recipeDistribution: Array<RecipeDistribution>;
};

export type PrescriptionDiet = {
  __typename?: 'PrescriptionDiet';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['Int']['output'];
  productName: Scalars['String']['output'];
  sku: Scalars['String']['output'];
};

export type ProductLineInput = {
  current: ProductLineType;
  eligible?: InputMaybe<Array<ProductLineType>>;
};

export enum ProductLineType {
  Diy = 'diy',
  Fresh = 'fresh',
}

export type ProductLineView = {
  __typename?: 'ProductLineView';
  current: ProductLineType;
  eligible: Maybe<Array<ProductLineType>>;
};

export type Query = {
  __typename?: 'Query';
  availableProducts: AvailableProductsView;
  availableRecipes: Array<AvailableRecipe>;
  blueprint: Maybe<Scalars['JSON']['output']>;
  doesPhoneNumberExist: Maybe<Scalars['Boolean']['output']>;
  fetchQuote: QuoteResponse;
  lead: LeadView;
  petRecipes: Array<FreshRecipeRecommendation>;
  staticData: StaticData;
  validateBreedsWeight: Maybe<Scalars['Boolean']['output']>;
  validateZipcode: ValidateZipcodeView;
  version: Scalars['String']['output'];
};

export type QueryBlueprintArgs = {
  units: InputMaybe<UnitInput>;
  version: InputMaybe<Scalars['String']['input']>;
};

export type QueryDoesPhoneNumberExistArgs = {
  email: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type QueryFetchQuoteArgs = {
  discountCode: InputMaybe<Scalars['String']['input']>;
  discountType: InputMaybe<DiscountTypeEnum>;
  pets: Array<PetInputForQuote>;
  selection: InputMaybe<LeadSelectionInput>;
  shippingAddress: InputMaybe<ShippingAddressInput>;
  shippingZip: InputMaybe<Scalars['String']['input']>;
  units: UnitInput;
};

export type QueryPetRecipesArgs = {
  allPetsCaloriesSum: Scalars['Int']['input'];
  petName: Scalars['String']['input'];
};

export type QueryValidateBreedsWeightArgs = {
  breeds: Array<Scalars['String']['input']>;
  units: UnitInput;
  weight: Scalars['Float']['input'];
};

export type QueryValidateZipcodeArgs = {
  zipcode: Scalars['String']['input'];
};

export type QuoteResponse = {
  __typename?: 'QuoteResponse';
  boxProgressionStrategy: Maybe<BoxProgressionStrategy>;
  regularOrder: OrderQuote;
  subscribeAndSaveQuote: Maybe<SubscribeAndSaveQuote>;
  /** This field is temporarily available only for debugging purposes */
  temporaryPetPlans: Array<Plan>;
  trial: TrialQuote;
  trialOrder: OrderQuote;
};

export type Recipe = {
  __typename?: 'Recipe';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type RecipeDistribution = {
  __typename?: 'RecipeDistribution';
  key: Scalars['String']['output'];
  ratio: Scalars['Float']['output'];
};

export type RecommendedCaloriesReturn = {
  __typename?: 'RecommendedCaloriesReturn';
  recommendedDailyKCal: Scalars['Int']['output'];
};

export type RegisterLeadResponse = {
  __typename?: 'RegisterLeadResponse';
  lead: LeadView;
  user: Maybe<Scalars['JSONObject']['output']>;
};

export type ShippingAddressInput = {
  addressLine1: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  stateAbbr: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type State = {
  __typename?: 'State';
  abbr: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type StaticData = {
  __typename?: 'StaticData';
  activityLevels: Array<ActivityLevel>;
  bodyConditions: Array<BodyCondition>;
  breeds: Array<Breed>;
  countries: Array<Country>;
  eatingStyles: Array<EatingStyle>;
  foodTypes: Array<FoodType>;
  freshFoodConfidences: Array<FreshFoodConfidence>;
  healthIssues: Array<HealthIssue>;
  personalityTypes: Array<PersonalityType>;
  prescriptionDiets: Array<PrescriptionDiet>;
  recipes: Array<Recipe>;
  treatUsageBuckets: Array<TreatUsageBucket>;
  treats: Array<Product>;
  validStates: Array<Scalars['String']['output']>;
};

export type SubscribeAndSaveQuote = {
  __typename?: 'SubscribeAndSaveQuote';
  orderDiscountDollarAmount: Scalars['Float']['output'];
  subscribeAndSaveDiscount: Scalars['Float']['output'];
  subscribeAndSaveOrderSubtotal: Scalars['Float']['output'];
  treatsDiscountDollarAmount: Scalars['Float']['output'];
};

export type Treat = {
  __typename?: 'Treat';
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: TreatSize;
};

export type TreatInput = {
  name: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  size: TreatSize;
};

export enum TreatSize {
  Large = 'large',
  Regular = 'regular',
}

export type TreatUsageBucket = {
  __typename?: 'TreatUsageBucket';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type TrialQuote = {
  __typename?: 'TrialQuote';
  /** price breakdown of trial box */
  breakdown: TrialQuoteBreakdown;
  /** how many days of food in the trial box */
  daysOfFood: Scalars['Float']['output'];
  /** the trial order discount */
  discount: TrialQuoteDiscount;
  /** the tax amount of the trial box */
  tax: Maybe<Scalars['Int']['output']>;
};

export type TrialQuoteBreakdown = {
  __typename?: 'TrialQuoteBreakdown';
  /** daily breakdown of trial box */
  daily: Array<TrialQuoteBreakdownDaily>;
};

export type TrialQuoteBreakdownDaily = {
  __typename?: 'TrialQuoteBreakdownDaily';
  fresh: Maybe<TrialQuoteBreakdownDailyFresh>;
  petName: Scalars['String']['output'];
};

export type TrialQuoteBreakdownDailyFresh = {
  __typename?: 'TrialQuoteBreakdownDailyFresh';
  /** pre-tax and pre-discount daily price for fresh */
  subtotal: Scalars['Int']['output'];
};

export type TrialQuoteDiscount = {
  __typename?: 'TrialQuoteDiscount';
  /** trial discount amount */
  amount: Scalars['Int']['output'];
  /** trial discount percentage */
  percentage: Scalars['Int']['output'];
  /** trial discounted pre-tax subtotal */
  subtotal: Scalars['Int']['output'];
};

export type UnitInput = {
  mass: MassUnit;
};

export type UpdateUserProductEligibilityResponse = {
  __typename?: 'UpdateUserProductEligibilityResponse';
  productLines: Array<Scalars['String']['output']>;
};

export enum UserProductEligibilityProductLine {
  Lpf = 'lpf',
}

export type ValidateZipcodeView = {
  __typename?: 'ValidateZipcodeView';
  zipcode: Maybe<Scalars['String']['output']>;
};

export type FullBirthdayViewFragment = {
  __typename?: 'BirthdayView';
  unit: string;
  amount: number;
  referencedAt: string;
};

export type FullBreakdownViewFragment = {
  __typename?: 'OrderQuoteBreakdown';
  daily: {
    __typename?: 'OrderQuoteDailyBreakdown';
    amount: number;
    pets: Array<{
      __typename?: 'OrderQuotePetDailyBreakdown';
      amount: number;
      discountedAmount: number;
      name: string;
    }>;
  };
  weekly: { __typename?: 'OrderQuoteWeeklyBreakdown'; amount: number };
  lineItems: {
    __typename?: 'OrderQuoteLineItems';
    treats: Array<{
      __typename?: 'OrderQuoteTreat';
      amount: number;
      discountedAmount: number;
      displayName: string;
      name: string;
      quantity: number;
      size: TreatSize;
    }>;
  };
};

export type FullConsentViewFragment = {
  __typename?: 'ConsentView';
  dnss: boolean | null;
};

export type FullDiscountViewFragment = {
  __typename?: 'DiscountView';
  code: string;
  type: DiscountType;
  associatedAt: string;
};

export type FullLeadSelectionViewFragment = {
  __typename?: 'LeadSelectionView';
  treats: Array<{
    __typename?: 'LeadSelectionTreat';
    name: string;
    quantity: number;
    size: TreatSize;
  }> | null;
};

export type FullLeadViewWebsiteFragment = {
  __typename?: 'LeadView';
  anonymousId: string | null;
  corePostgresUserId: number | null;
  createdAt: string | null;
  blueprintVersion: string | null;
  email: string;
  firstName: string | null;
  zip: string | null;
  freshFoodConfidence: string | null;
  humanId: string | null;
  phoneConsent: boolean | null;
  temporaryGrainsDiscount: boolean | null;
  pets: Array<{
    __typename?: 'PetView';
    name: string;
    breeds: Array<string>;
    weight: number | null;
    targetWeight: number | null;
    birthday: string | null;
    neutered: boolean | null;
    activityLevel: string | null;
    bodyCondition: string | null;
    nature: string | null;
    eatingStyle: string | null;
    gender: string | null;
    treatsQuantity: string | null;
    foodType: string | null;
    foodBrand: string | null;
    issues: Array<string>;
    prescriptionDiets: Array<string>;
    recommendedCalories: number | null;
    birthdayInput: {
      __typename?: 'BirthdayView';
      unit: string;
      amount: number;
      referencedAt: string;
    } | null;
    selection: {
      __typename?: 'PetProductSelection';
      fresh: {
        __typename?: 'PetProductFreshSelection';
        productLine: ProductLineType;
        createdAt: string;
        options: {
          __typename?: 'PetProductFreshSelectionOptions';
          recipes: Array<string>;
        };
      } | null;
      diy: {
        __typename?: 'PetProductDiySelection';
        productLine: ProductLineType;
        createdAt: string;
        options: {
          __typename?: 'PetProductDiySelectionOptions';
          quantity: number;
          cadence: number;
        };
      } | null;
    } | null;
  }>;
  discount: {
    __typename?: 'DiscountView';
    code: string;
    type: DiscountType;
    associatedAt: string;
  } | null;
  productLine: {
    __typename?: 'ProductLineView';
    current: ProductLineType;
    eligible: Array<ProductLineType> | null;
  } | null;
  consent: { __typename?: 'ConsentView'; dnss: boolean | null } | null;
  selection: {
    __typename?: 'LeadSelectionView';
    treats: Array<{
      __typename?: 'LeadSelectionTreat';
      name: string;
      quantity: number;
      size: TreatSize;
    }> | null;
  } | null;
};

export type FullOrderQuoteViewFragment = {
  __typename?: 'OrderQuote';
  grandTotal: number;
  preTaxDiscountedSubtotal: number;
  tax: number | null;
  breakdown: {
    __typename?: 'OrderQuoteBreakdown';
    daily: {
      __typename?: 'OrderQuoteDailyBreakdown';
      amount: number;
      pets: Array<{
        __typename?: 'OrderQuotePetDailyBreakdown';
        amount: number;
        discountedAmount: number;
        name: string;
      }>;
    };
    weekly: { __typename?: 'OrderQuoteWeeklyBreakdown'; amount: number };
    lineItems: {
      __typename?: 'OrderQuoteLineItems';
      treats: Array<{
        __typename?: 'OrderQuoteTreat';
        amount: number;
        discountedAmount: number;
        displayName: string;
        name: string;
        quantity: number;
        size: TreatSize;
      }>;
    };
  };
  discount: {
    __typename?: 'OrderQuoteDiscount';
    discountAmount: number;
    discountPercentage: number;
  };
  planDetails: { __typename?: 'OrderQuotePlanDetails'; daysOfFood: number };
};

export type FullPetViewWebsiteFragment = {
  __typename?: 'PetView';
  name: string;
  breeds: Array<string>;
  weight: number | null;
  targetWeight: number | null;
  birthday: string | null;
  neutered: boolean | null;
  activityLevel: string | null;
  bodyCondition: string | null;
  nature: string | null;
  eatingStyle: string | null;
  gender: string | null;
  treatsQuantity: string | null;
  foodType: string | null;
  foodBrand: string | null;
  issues: Array<string>;
  prescriptionDiets: Array<string>;
  recommendedCalories: number | null;
  birthdayInput: {
    __typename?: 'BirthdayView';
    unit: string;
    amount: number;
    referencedAt: string;
  } | null;
  selection: {
    __typename?: 'PetProductSelection';
    fresh: {
      __typename?: 'PetProductFreshSelection';
      productLine: ProductLineType;
      createdAt: string;
      options: {
        __typename?: 'PetProductFreshSelectionOptions';
        recipes: Array<string>;
      };
    } | null;
    diy: {
      __typename?: 'PetProductDiySelection';
      productLine: ProductLineType;
      createdAt: string;
      options: {
        __typename?: 'PetProductDiySelectionOptions';
        quantity: number;
        cadence: number;
      };
    } | null;
  } | null;
};

export type FullProductLineViewFragment = {
  __typename?: 'ProductLineView';
  current: ProductLineType;
  eligible: Array<ProductLineType> | null;
};

export type FullLeadRegisterResponseFragment = {
  __typename?: 'RegisterLeadResponse';
  user: Record<string, unknown> | null;
  lead: {
    __typename?: 'LeadView';
    anonymousId: string | null;
    corePostgresUserId: number | null;
    createdAt: string | null;
    blueprintVersion: string | null;
    email: string;
    firstName: string | null;
    zip: string | null;
    freshFoodConfidence: string | null;
    humanId: string | null;
    phoneConsent: boolean | null;
    temporaryGrainsDiscount: boolean | null;
    pets: Array<{
      __typename?: 'PetView';
      name: string;
      breeds: Array<string>;
      weight: number | null;
      targetWeight: number | null;
      birthday: string | null;
      neutered: boolean | null;
      activityLevel: string | null;
      bodyCondition: string | null;
      nature: string | null;
      eatingStyle: string | null;
      gender: string | null;
      treatsQuantity: string | null;
      foodType: string | null;
      foodBrand: string | null;
      issues: Array<string>;
      prescriptionDiets: Array<string>;
      recommendedCalories: number | null;
      birthdayInput: {
        __typename?: 'BirthdayView';
        unit: string;
        amount: number;
        referencedAt: string;
      } | null;
      selection: {
        __typename?: 'PetProductSelection';
        fresh: {
          __typename?: 'PetProductFreshSelection';
          productLine: ProductLineType;
          createdAt: string;
          options: {
            __typename?: 'PetProductFreshSelectionOptions';
            recipes: Array<string>;
          };
        } | null;
        diy: {
          __typename?: 'PetProductDiySelection';
          productLine: ProductLineType;
          createdAt: string;
          options: {
            __typename?: 'PetProductDiySelectionOptions';
            quantity: number;
            cadence: number;
          };
        } | null;
      } | null;
    }>;
    discount: {
      __typename?: 'DiscountView';
      code: string;
      type: DiscountType;
      associatedAt: string;
    } | null;
    productLine: {
      __typename?: 'ProductLineView';
      current: ProductLineType;
      eligible: Array<ProductLineType> | null;
    } | null;
    consent: { __typename?: 'ConsentView'; dnss: boolean | null } | null;
    selection: {
      __typename?: 'LeadSelectionView';
      treats: Array<{
        __typename?: 'LeadSelectionTreat';
        name: string;
        quantity: number;
        size: TreatSize;
      }> | null;
    } | null;
  };
};

export type CreateLeadMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type CreateLeadMutation = {
  __typename?: 'Mutation';
  createLead: {
    __typename?: 'CreateLeadResponse';
    token: string | null;
    recoverable: boolean;
  };
};

export type RegisterLeadMutationVariables = Exact<{
  lead: LeadInput;
  disableRecipesUpdate: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type RegisterLeadMutation = {
  __typename?: 'Mutation';
  registerLead: {
    __typename?: 'RegisterLeadResponse';
    user: Record<string, unknown> | null;
    lead: {
      __typename?: 'LeadView';
      anonymousId: string | null;
      corePostgresUserId: number | null;
      createdAt: string | null;
      blueprintVersion: string | null;
      email: string;
      firstName: string | null;
      zip: string | null;
      freshFoodConfidence: string | null;
      humanId: string | null;
      phoneConsent: boolean | null;
      temporaryGrainsDiscount: boolean | null;
      pets: Array<{
        __typename?: 'PetView';
        name: string;
        breeds: Array<string>;
        weight: number | null;
        targetWeight: number | null;
        birthday: string | null;
        neutered: boolean | null;
        activityLevel: string | null;
        bodyCondition: string | null;
        nature: string | null;
        eatingStyle: string | null;
        gender: string | null;
        treatsQuantity: string | null;
        foodType: string | null;
        foodBrand: string | null;
        issues: Array<string>;
        prescriptionDiets: Array<string>;
        recommendedCalories: number | null;
        birthdayInput: {
          __typename?: 'BirthdayView';
          unit: string;
          amount: number;
          referencedAt: string;
        } | null;
        selection: {
          __typename?: 'PetProductSelection';
          fresh: {
            __typename?: 'PetProductFreshSelection';
            productLine: ProductLineType;
            createdAt: string;
            options: {
              __typename?: 'PetProductFreshSelectionOptions';
              recipes: Array<string>;
            };
          } | null;
          diy: {
            __typename?: 'PetProductDiySelection';
            productLine: ProductLineType;
            createdAt: string;
            options: {
              __typename?: 'PetProductDiySelectionOptions';
              quantity: number;
              cadence: number;
            };
          } | null;
        } | null;
      }>;
      discount: {
        __typename?: 'DiscountView';
        code: string;
        type: DiscountType;
        associatedAt: string;
      } | null;
      productLine: {
        __typename?: 'ProductLineView';
        current: ProductLineType;
        eligible: Array<ProductLineType> | null;
      } | null;
      consent: { __typename?: 'ConsentView'; dnss: boolean | null } | null;
      selection: {
        __typename?: 'LeadSelectionView';
        treats: Array<{
          __typename?: 'LeadSelectionTreat';
          name: string;
          quantity: number;
          size: TreatSize;
        }> | null;
      } | null;
    };
  };
};

export type SyncLeadWithUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
  skipSyncIfNoRecipeSelection: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SyncLeadWithUserMutation = {
  __typename?: 'Mutation';
  syncLeadWithUser: {
    __typename?: 'RegisterLeadResponse';
    user: Record<string, unknown> | null;
  };
};

export type UpdateLeadMutationVariables = Exact<{
  lead: LeadInput;
}>;

export type UpdateLeadMutation = {
  __typename?: 'Mutation';
  updateLead: {
    __typename?: 'LeadView';
    anonymousId: string | null;
    corePostgresUserId: number | null;
    createdAt: string | null;
    blueprintVersion: string | null;
    email: string;
    firstName: string | null;
    zip: string | null;
    freshFoodConfidence: string | null;
    humanId: string | null;
    phoneConsent: boolean | null;
    temporaryGrainsDiscount: boolean | null;
    pets: Array<{
      __typename?: 'PetView';
      name: string;
      breeds: Array<string>;
      weight: number | null;
      targetWeight: number | null;
      birthday: string | null;
      neutered: boolean | null;
      activityLevel: string | null;
      bodyCondition: string | null;
      nature: string | null;
      eatingStyle: string | null;
      gender: string | null;
      treatsQuantity: string | null;
      foodType: string | null;
      foodBrand: string | null;
      issues: Array<string>;
      prescriptionDiets: Array<string>;
      recommendedCalories: number | null;
      birthdayInput: {
        __typename?: 'BirthdayView';
        unit: string;
        amount: number;
        referencedAt: string;
      } | null;
      selection: {
        __typename?: 'PetProductSelection';
        fresh: {
          __typename?: 'PetProductFreshSelection';
          productLine: ProductLineType;
          createdAt: string;
          options: {
            __typename?: 'PetProductFreshSelectionOptions';
            recipes: Array<string>;
          };
        } | null;
        diy: {
          __typename?: 'PetProductDiySelection';
          productLine: ProductLineType;
          createdAt: string;
          options: {
            __typename?: 'PetProductDiySelectionOptions';
            quantity: number;
            cadence: number;
          };
        } | null;
      } | null;
    }>;
    discount: {
      __typename?: 'DiscountView';
      code: string;
      type: DiscountType;
      associatedAt: string;
    } | null;
    productLine: {
      __typename?: 'ProductLineView';
      current: ProductLineType;
      eligible: Array<ProductLineType> | null;
    } | null;
    consent: { __typename?: 'ConsentView'; dnss: boolean | null } | null;
    selection: {
      __typename?: 'LeadSelectionView';
      treats: Array<{
        __typename?: 'LeadSelectionTreat';
        name: string;
        quantity: number;
        size: TreatSize;
      }> | null;
    } | null;
  };
};

export type FetchAvailableProductsQueryVariables = Exact<{
  discountCode: InputMaybe<Scalars['String']['input']>;
  discountType: InputMaybe<DiscountType>;
}>;

export type FetchAvailableProductsQuery = {
  __typename?: 'Query';
  availableProducts: {
    __typename?: 'AvailableProductsView';
    treats: Array<{
      __typename?: 'Treat';
      displayName: string;
      name: string;
      size: TreatSize;
    }>;
  };
};

export type FetchAvailableRecipesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchAvailableRecipesQuery = {
  __typename?: 'Query';
  availableRecipes: Array<{
    __typename?: 'AvailableRecipe';
    name: string;
    displayName: string;
    type: AvailableRecipeType;
  }>;
};

export type FetchLeadQueryVariables = Exact<{ [key: string]: never }>;

export type FetchLeadQuery = {
  __typename?: 'Query';
  lead: {
    __typename?: 'LeadView';
    anonymousId: string | null;
    corePostgresUserId: number | null;
    createdAt: string | null;
    blueprintVersion: string | null;
    email: string;
    firstName: string | null;
    zip: string | null;
    freshFoodConfidence: string | null;
    humanId: string | null;
    phoneConsent: boolean | null;
    temporaryGrainsDiscount: boolean | null;
    pets: Array<{
      __typename?: 'PetView';
      name: string;
      breeds: Array<string>;
      weight: number | null;
      targetWeight: number | null;
      birthday: string | null;
      neutered: boolean | null;
      activityLevel: string | null;
      bodyCondition: string | null;
      nature: string | null;
      eatingStyle: string | null;
      gender: string | null;
      treatsQuantity: string | null;
      foodType: string | null;
      foodBrand: string | null;
      issues: Array<string>;
      prescriptionDiets: Array<string>;
      recommendedCalories: number | null;
      birthdayInput: {
        __typename?: 'BirthdayView';
        unit: string;
        amount: number;
        referencedAt: string;
      } | null;
      selection: {
        __typename?: 'PetProductSelection';
        fresh: {
          __typename?: 'PetProductFreshSelection';
          productLine: ProductLineType;
          createdAt: string;
          options: {
            __typename?: 'PetProductFreshSelectionOptions';
            recipes: Array<string>;
          };
        } | null;
        diy: {
          __typename?: 'PetProductDiySelection';
          productLine: ProductLineType;
          createdAt: string;
          options: {
            __typename?: 'PetProductDiySelectionOptions';
            quantity: number;
            cadence: number;
          };
        } | null;
      } | null;
    }>;
    discount: {
      __typename?: 'DiscountView';
      code: string;
      type: DiscountType;
      associatedAt: string;
    } | null;
    productLine: {
      __typename?: 'ProductLineView';
      current: ProductLineType;
      eligible: Array<ProductLineType> | null;
    } | null;
    consent: { __typename?: 'ConsentView'; dnss: boolean | null } | null;
    selection: {
      __typename?: 'LeadSelectionView';
      treats: Array<{
        __typename?: 'LeadSelectionTreat';
        name: string;
        quantity: number;
        size: TreatSize;
      }> | null;
    } | null;
  };
};

export type FetchQuoteQueryVariables = Exact<{
  pets: Array<PetInputForQuote> | PetInputForQuote;
  shippingZip: InputMaybe<Scalars['String']['input']>;
  shippingAddress: InputMaybe<ShippingAddressInput>;
  discountCode: InputMaybe<Scalars['String']['input']>;
  discountType: InputMaybe<DiscountTypeEnum>;
  selection: InputMaybe<LeadSelectionInput>;
}>;

export type FetchQuoteQuery = {
  __typename?: 'Query';
  fetchQuote: {
    __typename?: 'QuoteResponse';
    boxProgressionStrategy: BoxProgressionStrategy | null;
    subscribeAndSaveQuote: {
      __typename?: 'SubscribeAndSaveQuote';
      subscribeAndSaveOrderSubtotal: number;
      subscribeAndSaveDiscount: number;
      orderDiscountDollarAmount: number;
      treatsDiscountDollarAmount: number;
    } | null;
    trialOrder: {
      __typename?: 'OrderQuote';
      grandTotal: number;
      preTaxDiscountedSubtotal: number;
      tax: number | null;
      breakdown: {
        __typename?: 'OrderQuoteBreakdown';
        daily: {
          __typename?: 'OrderQuoteDailyBreakdown';
          amount: number;
          pets: Array<{
            __typename?: 'OrderQuotePetDailyBreakdown';
            amount: number;
            discountedAmount: number;
            name: string;
          }>;
        };
        weekly: { __typename?: 'OrderQuoteWeeklyBreakdown'; amount: number };
        lineItems: {
          __typename?: 'OrderQuoteLineItems';
          treats: Array<{
            __typename?: 'OrderQuoteTreat';
            amount: number;
            discountedAmount: number;
            displayName: string;
            name: string;
            quantity: number;
            size: TreatSize;
          }>;
        };
      };
      discount: {
        __typename?: 'OrderQuoteDiscount';
        discountAmount: number;
        discountPercentage: number;
      };
      planDetails: { __typename?: 'OrderQuotePlanDetails'; daysOfFood: number };
    };
    regularOrder: {
      __typename?: 'OrderQuote';
      grandTotal: number;
      preTaxDiscountedSubtotal: number;
      tax: number | null;
      breakdown: {
        __typename?: 'OrderQuoteBreakdown';
        daily: {
          __typename?: 'OrderQuoteDailyBreakdown';
          amount: number;
          pets: Array<{
            __typename?: 'OrderQuotePetDailyBreakdown';
            amount: number;
            discountedAmount: number;
            name: string;
          }>;
        };
        weekly: { __typename?: 'OrderQuoteWeeklyBreakdown'; amount: number };
        lineItems: {
          __typename?: 'OrderQuoteLineItems';
          treats: Array<{
            __typename?: 'OrderQuoteTreat';
            amount: number;
            discountedAmount: number;
            displayName: string;
            name: string;
            quantity: number;
            size: TreatSize;
          }>;
        };
      };
      discount: {
        __typename?: 'OrderQuoteDiscount';
        discountAmount: number;
        discountPercentage: number;
      };
      planDetails: { __typename?: 'OrderQuotePlanDetails'; daysOfFood: number };
    };
    temporaryPetPlans: Array<{ __typename?: 'Plan'; dailyKCal: number }>;
  };
};
