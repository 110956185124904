import { useCallback, useEffect, useState } from 'react';

import { useViewport } from '@farmersdog/corgi-x';
import { trackModuleViewed } from 'src/analytics/events/trackModuleViewed';
import Page from 'src/components/Page';
import { Drawer } from 'src/components/Drawer';
import {
  BenefitCardVariant,
  DrawerContent,
  HomepageSections,
} from '../../components';
import { FullFooter } from 'src/components/Footer';
import { HomepageRedesignSections } from '../CtaTrackerV2';

import styles from './HomepageContent.module.css';

export const HomepageContent = () => {
  const isDesktop = useViewport().lg;

  const [drawerDataKey, setDrawerDataKey] = useState<BenefitCardVariant>(
    BenefitCardVariant.WeightManagement
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = useCallback((key: BenefitCardVariant) => {
    setDrawerDataKey(key);
    setIsDrawerOpen(true);
  }, []);

  const onCloseDrawer = () => setIsDrawerOpen(false);

  if (isDesktop && isDrawerOpen) {
    onCloseDrawer();
  }

  useEffect(() => {
    if (isDrawerOpen) {
      trackModuleViewed({
        moduleName: HomepageRedesignSections.LifetimeOfBenefits,
        moduleLevel: 2,
      });
    }
  }, [isDrawerOpen]);

  return (
    <>
      <Page className={styles.container}>
        <HomepageSections onDrawerOpen={handleDrawerOpen} />
        <FullFooter />
      </Page>
      <Drawer
        isOpen={isDrawerOpen}
        onClose={onCloseDrawer}
        id="lifetime-benefits-drawer"
        aria-labelled-by="lifetime-benefits-drawer-title"
      >
        <DrawerContent contentKey={drawerDataKey} onClose={onCloseDrawer} />
      </Drawer>
    </>
  );
};
