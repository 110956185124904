import {
  Text,
  ButtonBase,
  Picture,
  generateMobileSourcesForPicture,
} from '@farmersdog/corgi-x';
import { paths } from '@farmersdog/constants';

import { ExtendedButton } from '../../../ExtendedButton';

import CheckIcon from '../../assets/check-icon.svg';
import MinimizeIcon from '../../assets/minimize-icon.svg';

import type { BenefitCardVariant } from '../../data';
import { benefitDataMap } from '../../data';
import { CtaTrackerV2, HomepageRedesignSections } from '../../../CtaTrackerV2';

import styles from './DrawerContent.module.css';

interface DrawerContentProps {
  contentKey: BenefitCardVariant;
  onClose: () => void;
}

export const DrawerContent = ({ contentKey, onClose }: DrawerContentProps) => {
  const { title, benefits, testimonial, mobileSources } =
    benefitDataMap[contentKey];

  const sourcesForPicture = generateMobileSourcesForPicture(mobileSources);

  return (
    <div className={styles.container}>
      <header className={styles.imageContainer}>
        <Picture
          className={styles.image}
          sources={sourcesForPicture}
          loading="lazy"
          alt={title}
        />
        <Text
          as="div"
          className={styles.pill}
          color="white"
          variant="heading-12"
          bold
        >
          {contentKey}
        </Text>
      </header>
      <Text
        id="lifetime-benefits-drawer-title"
        as="div"
        color="kale-3"
        variant="heading-22"
        bold
        leftSpacing="md"
        topSpacing="md"
        rightSpacing="sm"
      >
        {title}
      </Text>
      <div className={styles.content}>
        <ul className={styles.list}>
          {benefits.map((benefit, index) => (
            <li key={`${benefit}-${index}`}>
              <CheckIcon />
              <Text
                bold
                as="p"
                color="kale-3"
                variant="heading-12"
                topSpacing="xs"
                bottomSpacing="xs"
              >
                {benefit}
              </Text>
            </li>
          ))}
        </ul>
        <Text
          as="p"
          topSpacing="none"
          bottomSpacing="none"
          variant="heading-12"
          weight="semibold"
          className={styles.quoteTitle}
        >
          Heard around the park
        </Text>
        <blockquote className={styles.blockquote}>
          <Text
            as="p"
            topSpacing="none"
            bottomSpacing="xs"
            variant="article-12"
          >
            &quot;{testimonial.quote}&quot;
          </Text>
          <footer>
            <Text bold variant="heading-12">
              - {testimonial.author}
            </Text>
          </footer>
        </blockquote>
        <div className={styles.ctaContainer}>
          <CtaTrackerV2
            type="select"
            moduleLevel={2}
            moduleName={HomepageRedesignSections.LifetimeOfBenefits}
          >
            <ExtendedButton
              type="link"
              to={paths.PATH_SIGNUP}
              variant="bordered-charcoal"
              className={styles.button}
            >
              Get Started
            </ExtendedButton>
          </CtaTrackerV2>
          <CtaTrackerV2
            type="exit"
            moduleLevel={2}
            moduleName={HomepageRedesignSections.LifetimeOfBenefits}
          >
            <ButtonBase className={styles.closeButton} onClick={onClose}>
              <MinimizeIcon />
            </ButtonBase>
          </CtaTrackerV2>
        </div>
      </div>
    </div>
  );
};
