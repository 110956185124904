import { applovinItem } from './constants';
import { mountAppLovinEvent } from './mountAppLovinEvent';
import { AppLovinEvent } from './types';

import type { ApplovinBaseEventProperties } from './types';

export function mountAppLovinAddToCart() {
  const properties: ApplovinBaseEventProperties = {
    currency: 'USD',
    items: [applovinItem],
  };

  mountAppLovinEvent({
    name: AppLovinEvent.AddToCart,
    properties,
  });
}
