import { useRef } from 'react';
import { Helmet } from 'react-helmet-async';

import { generateStructuredReviewData, reviewData } from './utils';

import { FullFooter } from 'src/components/Footer';
import { Navigation } from 'src/components/Navigation';
import Page from 'src/components/Page';

export const ReviewsPageRedesign = () => {
  const ctaReveal = useRef(null);

  const structuredReviewData = generateStructuredReviewData(reviewData);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{structuredReviewData}</script>
      </Helmet>
      <Navigation ctaTrigger={ctaReveal} />
      <Page title="Placeholder Title" description="Placeholder description">
        <div ref={ctaReveal}>
          Some content
          <FullFooter />
        </div>
      </Page>
    </>
  );
};
