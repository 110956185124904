import { useTrackedSectionRef } from 'src/hooks/useTrackedSections';
import {
  LifetimeOfBenefitsMobile,
  LifetimeBenefitsDesktop,
} from './components';
import type { BenefitCardVariant } from './data';
import { HomepageRedesignSections } from '../CtaTrackerV2';

interface LifetimeOfBenefitsProps {
  onTileClick: (key: BenefitCardVariant) => void;
}

export const LifetimeOfBenefits = ({
  onTileClick,
}: LifetimeOfBenefitsProps) => {
  const sectionRef = useTrackedSectionRef(
    HomepageRedesignSections.LifetimeOfBenefits
  );
  return (
    <div ref={sectionRef}>
      <LifetimeOfBenefitsMobile onTileClick={onTileClick} />
      <LifetimeBenefitsDesktop />
    </div>
  );
};
