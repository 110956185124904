import { applovinItem } from './constants';
import { mountAppLovinEvent } from './mountAppLovinEvent';
import { AppLovinEvent } from './types';

import type { ApplovinBaseEventProperties } from './types';
import type { PixelRevenue } from '../../types';

interface MountAppLovinPurchaseArgs {
  transactionId: string;
}

interface ApplovinPurchaseProperties extends ApplovinBaseEventProperties {
  transaction_id: string;
  value: PixelRevenue;
}

export function mountAppLovinPurchase({
  transactionId,
}: MountAppLovinPurchaseArgs) {
  const properties: ApplovinPurchaseProperties = {
    transaction_id: transactionId,
    value: 0,
    currency: 'USD',
    items: [applovinItem],
  };

  mountAppLovinEvent({ name: AppLovinEvent.Purchase, properties });
}
