import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_RECIPES,
} from '@farmersdog/constants/paths';
import {
  useMountPinterestPageView,
  useMountNextDoorPageView,
  useMountAmazonTagPageView,
  useMountGa4PageView,
  useMountAudacyPageView,
  useMountPostiePageView,
  mountSpotifyPageView,
  mountSnapchatPageView,
  mountRedditPageVisit,
  mountAppLovinPageView,
} from '@farmersdog/pixels';

import { useMountTikTokPageView } from './useMountTikTokPageView';

interface UseHandlePageViewArgs {
  isFirstPetsPage: boolean;
  email: string | undefined;
}

const SNAPCHAT_PAGEVIEW_ROUTES = [
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_RECIPES,
];

const REDDIT_PAGEVIEW_ROUTES = [
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_RECIPES,
];

const APPLOVIN_PAGEVIEW_ROUTES = [
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_RECIPES,
];

export function useHandlePageView({
  isFirstPetsPage,
  email,
}: UseHandlePageViewArgs): void {
  const { pathname } = useLocation();

  useMountTikTokPageView({ email, isFirstPetsPage });
  useMountGa4PageView();
  useMountPinterestPageView();
  useMountAudacyPageView();
  useMountNextDoorPageView({ isFirstPetsPage });
  useMountAmazonTagPageView({ isFirstPetsPage });
  useMountPostiePageView();

  // for pageview functions that should fire when the path (not querystring) changes
  useEffect(() => {
    if (SNAPCHAT_PAGEVIEW_ROUTES.includes(pathname)) {
      mountSnapchatPageView();
    }
    if (REDDIT_PAGEVIEW_ROUTES.includes(pathname)) {
      mountRedditPageVisit();
    }
    if (APPLOVIN_PAGEVIEW_ROUTES.includes(pathname)) {
      mountAppLovinPageView();
    }
    mountSpotifyPageView(pathname);
  }, [pathname]);

  // if we need to fire pageviews on querystring changes as well (ie. different signup flow cards) we can create a different effect that includes search in the dependency array
}
