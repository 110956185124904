import { Picture, generateDesktopSourcesForPicture } from '@farmersdog/corgi-x';
import { ExtendedText } from '../../../ExtendedText';

import type { Sources } from '../../data';
import { CtaTrackerV2, HomepageRedesignSections } from '../../../CtaTrackerV2';

import OpenIcon from '../../assets/open-icon.svg';

import styles from './BenefitCard.module.css';

type TransformOrigin =
  | 'center'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top left'
  | 'top right'
  | 'bottom left'
  | 'bottom right';

interface BenefitCardProps {
  alt: string;
  sources: Sources;
  title: React.ReactElement;
  onClick: () => void;
  zoomOrigin?: TransformOrigin;
  ariaLabel: string;
}

export const BenefitCard = ({
  alt,
  sources,
  title,
  onClick,
  zoomOrigin = 'center',
  ariaLabel,
}: BenefitCardProps) => {
  const desktopSources = generateDesktopSourcesForPicture(sources);
  return (
    <CtaTrackerV2
      type="select"
      moduleLevel={1}
      moduleName={HomepageRedesignSections.LifetimeOfBenefits}
    >
      <div
        role="button"
        tabIndex={0}
        className={styles.card}
        onClick={onClick}
        aria-label={ariaLabel}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onClick();
          }
        }}
      >
        <Picture
          alt={alt}
          sources={desktopSources}
          loading="lazy"
          className={styles.image}
          style={{ transformOrigin: zoomOrigin }}
        />
        <ExtendedText
          className={styles.title}
          color="white"
          variant="heading-28"
          bold
        >
          {title}
        </ExtendedText>
        <OpenIcon className={styles.openButton} />
      </div>
    </CtaTrackerV2>
  );
};
