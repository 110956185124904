import { AppLovinMethod } from './types';

import type { ScriptFunctionWithFallbackReturn } from '../../types';

export function getAppLovinScript(
  initEventKey: string
): ScriptFunctionWithFallbackReturn {
  const fallbackScript = `
    !function(w) {
      if(!w.axon) {
        var a = w.axon = function() {
          a.performOperation ? a.performOperation.apply(a, arguments) : a.operationQueue.push(arguments);
        };
        a.operationQueue = [], a.ts = Date.now(), a.eventKey = '${initEventKey}';
      }
      axon('${AppLovinMethod.Init}');
    }(window);
  `;

  const sdkLoader = `
    !function(d) {
    var id = 'applovin-pixel'
    if (!d.getElementById(id)) {
      var t = ["https://s.axon.ai/pixel.js", "https://res4.applovin.com/p/l/loader.iife.js"];
      for (var n = d.getElementsByTagName("script")[0], o = 0; o < t.length; o++) {
        var i = d.createElement("script");
        i.async = !0, i.src=t[o], n.parentNode.insertBefore(i, n);
        i.id = id;
      }
    }
  }(document);
  `;

  return { fallbackScript, sdkLoader };
}
