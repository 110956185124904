import { PATH_SIGNUP } from '@farmersdog/constants/paths';
import { Flex } from '@farmersdog/corgi-x';

import { CopyButton } from './CopyButton';

interface CopyButtonGroupProps {
  leadEmail: string | undefined;
  userId: number | null | undefined;
}

export function CopyButtonGroup({ leadEmail, userId }: CopyButtonGroupProps) {
  return (
    <Flex wrap="wrap" gap="xs" alignItems="center">
      <CopyButton
        valueToCopy={
          leadEmail
            ? `${window.location.origin}${PATH_SIGNUP}/${leadEmail}`
            : undefined
        }
        label={'Recovery Link'}
      />
      <CopyButton valueToCopy={leadEmail} label={'Email'} />
      <CopyButton
        valueToCopy={userId ? String(userId) : undefined}
        label={'User ID'}
      />
    </Flex>
  );
}
