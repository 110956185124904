import type { ApplovinItem } from './constants';

export enum AppLovinMethod {
  Init = 'init',
  Track = 'track',
}

export enum AppLovinEvent {
  AddToCart = 'add_to_cart',
  GenerateLead = 'generate_lead',
  PageView = 'page_view',
  Purchase = 'purchase',
}

export type AppLovinTagMounter = (
  method: AppLovinMethod,
  eventName: AppLovinEvent,
  properties: Record<string, unknown>
) => void;

export interface ApplovinBaseEventProperties extends Record<string, unknown> {
  currency: 'USD';
  items: ApplovinItem[];
}
