import {
  PreloadLink,
  PreloadLinkAs,
} from '@farmersdog/tosa/src/components/shared/PreloadLink';

import { useHomepageHeroExperiment } from 'src/hooks/useHomepageHeroExperiment';
import { useShowHomepageRedesign } from 'src/hooks/useHomepageRedesign';
import { generateSourcesForPreloader } from 'src/pages/Home/components/AssetPreloader/assets/shared';

import { getSourcesForHomepageHero } from './getSourcesForHomepage';

import { getPreloadLinksForBenefitCards } from './utils/getPreloadLinksForBenefitCards';

export const AssetPreloader = () => {
  const { treatment: homepageRedesignTreatment } = useShowHomepageRedesign();
  const { treatment: homepageHeroTreatment } = useHomepageHeroExperiment();

  // Hero assets
  const { desktopSources, mobileSources } = getSourcesForHomepageHero({
    homepageHeroTreatment,
    homepageRedesignTreatment,
  });
  const heroAssets = generateSourcesForPreloader({
    mobileSources,
    desktopSources,
  });

  // Benefit card assets - only for OLS and Millie treatments on desktop
  const benefitCardAssets = getPreloadLinksForBenefitCards(
    homepageRedesignTreatment
  );

  // Combine all assets into a single array
  const allAssets = [...heroAssets, ...benefitCardAssets];

  return (
    <>
      {allAssets.map(({ imageSrcSet, media }) => (
        <PreloadLink
          key={imageSrcSet}
          rel="preload"
          as={PreloadLinkAs.Image}
          imageSrcSet={imageSrcSet}
          media={media}
        />
      ))}
    </>
  );
};
